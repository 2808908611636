.paginator{
	width: 100%;
	height: 2em;
	padding: 0;
  text-align: center;
  margin: 10px;
    margin-top: 35px;
}
.item{
	display: inline-block;
	text-align: center;
  border: 1px solid #d4d4d4;
  border-radius: 2px;
  margin: 0 2px;
  box-shadow: 1px 1px 1px 0 rgba(187,205,194,.2);
  height: 2em;
  width: 2em;
  line-height: 2em;
  box-sizing: initial;
}
.item span{
  font-size: 13px;
}
.arrow span{
  vertical-align: top;
  font-size: 14px;
}
.item:hover,
.item.active{
  color: black;
  border-color: #929090;
}
.item.active{
  font-weight: bold;
}
.clickable{
	cursor: pointer;
}
.inactive{
	cursor: default;
}
.digit{
}
.arrow{
	border: 1px solid #cecece;
}
