.chart-head-line{
  border-bottom: 1px solid #efefef;
  padding-bottom: 2px;
  margin: 5px 0 10px;
}
.progress-chart-navigation{
  text-align: right;
  margin-bottom: 0;
  padding: 5px 0;
}
.progress-chart-navigation li{
  display: inline-block;
  margin-left: 10px;
  border-radius: 5px;
  border: 1px solid #f3f3f3;
  padding: 0 5px;
  font-size: 12px;
  cursor: pointer;
  background: #fff;
}
.progress-chart-navigation li:hover,
.progress-chart-navigation li.active{
  background: #354156!important;
  color: #e5cb9a!important;
}
h5.green{
  font-size: 15px;
  letter-spacing: .05em;
  text-transform: uppercase;
  font-weight: 400;
  margin: 0 0 0 50px;
  color: #0f8e5b;
}
.bd-month-year li {
  display: inline-block;
  padding: 5px 15px;
  font-weight: bold;
  position: relative;
}
.bd-month-year > li:first-child {
  min-width: 100px;
}
.bd-month-year li ul  {
  position: absolute;
  display: none;
  min-width: 100px;
  background: #fff;
  z-index: 0;
  padding: 0;
  margin: 0;
  border: 1px solid #f5f5f5;
  top: auto;
  text-align: center;
  left: -18px;
}
.bd-month-year li:hover > ul {
  display: block;
}