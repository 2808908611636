.modal-back-layer{
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  background-color: rgba(0,0,0,0.4);
}
.modal-body{
  background: #fff;
  position: relative;
  padding: 30px 15px 15px 15px;
  white-space: pre-line;
}
.modal-body .close-button{
  position: absolute;
  top: 0;
  right: 0;
  display: inline-block;
  height: 24px;
  width: 24px;
  text-align: center;
  line-height: 22px;
  cursor: pointer;
}
.b-rounded-blue{
  border: 1px solid #e4ebee;
  border-radius: 2px;
}
