ul{
  list-style: none;
  margin: 0;
  padding: 0;
}
.pin-list-handler{
  width: 100%;
  height: 50px;
  margin: 0 0 10px 0;
}
.pin-list{
  width: calc(100% - 48px);
  height: 30px;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  margin-right: 10px;
  background: #ffffff;
  border: solid 1px #fefefe;
  box-shadow: 0 14px 26px -12px rgba(153, 153, 153, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(153, 153, 153, 0.2);
  border-radius: 3px;
}
.pin-item{
  /*float: left;*/
  position: relative;
  display: inline-block;
  padding: 0 8px;
  margin: 2px 6px;
  border: 1px solid #656565;
  border-radius: 5px;
  background: #ffffff;
}
.pin-inner{
  display: inline;
  vertical-align: middle;
  line-height: 1;
}
.pin-inner:hover{
  color: #ffa726;
}
.pin-item .item-icon{
  margin-right: 8px;
}
.pin-item .remove-icon{
  margin-left: 8px;
  cursor: pointer;
}
.pin-list-handler .clear-pins-button{
  position: relative;
  display: inline-block;
  font-size: 1.5em;
  border-radius: 3px;
  width: 32px;
  height: 32px;
  text-align: center;
  vertical-align: top;
  line-height: 1.2;
  box-shadow: 0 14px 26px -12px rgba(153, 153, 153, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(153, 153, 153, 0.2);
  background: #fff;
}
.trash-can{
  background: url(trashCan.png) center;
  background-size: cover;
}
.clear-pins-button .trash-can{
  width: 85%;
  height: 85%;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.clear-pins-button span{
  color: #000000;
}
.pin-list-handler .scroll-bar{
  height: 3px;
  position: absolute;
  border: 1px solid #787878;
  background: rgb(255, 255, 255);
  border-radius: 2px;
  transition: all .1s ease 0s;
}
.pin-list li:first-child{
  margin-left: 1px;
}
.pin-list li:last-child{
  margin-right: 1px;
}
.pin-item.active{
  background: #354156;
}
.pin-item.active, .pin-item.active .link{
  color: #fff;
}
