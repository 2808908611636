
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

.print-head-side, .print-bottom-side {
    width: 100%;
}

.list-subhead {
    color: silver;
    font-size: 21px;
    margin-top: 12px !important;
    margin-bottom: 10px !important;
    border-bottom: 1px solid whitesmoke;
}

.fintable td {
    font-size: 11px !important;
    padding: 2px 5px !important;
}

.finday td {
    background-color: #fff3bf !important;
}

.finday td b { font-weight: bold }

.select2-results__group {
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
    text-decoration: underline;
}

.defaultDish {
    color: green;
}
.gray-text {
  color: gray;
}
.sides-text button {
    margin-top: -3px;
    padding: 1px !important;
}

.sides-text {
    font-size: 12px;
    text-transform: uppercase;
    color: gray;
}

.print-head-side h1 {
    color: #3a3a3a;
    font-size: 36px;
    text-align: center;
}

.print-head-side h2 {
    color: #b79e5a;
    font-size: 24px;
    text-align: center;
    text-transform: uppercase;
}

.purple-text {
    color: #9c27b0 !important;
}

.print-bottom-side {
    padding: 10px;
    background-color: #caeedd;
    color: #595a56;
    font-size: 18px;
}

.print-middle-side {
    width: 100%;
    min-height: 160px;
}

.print-left-side {
    width: 60%;
    float: left;
    font-size: 18px;
    text-align: center;
}

.print-left-side p {
    padding: 10px;
}

.print-right-side b {
    color: #9a9a9a;
    padding-left: 20px;
}

.print-right-side {
    width: 40%;
    float: right;
    color: #87ad9c;
    font-size: 20px;
}

.lead-small, .lead-tr td {
    font-size: 12px !important;
    line-height: 14px !important;
    padding: 2px 5px !important;
}

.green-bg {
    background-color: #c1ffc1 !important;
    color: #354156 !important;
}

.color-text-1 {
    color: #ff6eb4;
}

.color-text-2 {
    color: #ff3300;
}
.color-text-3 {
    color: #fab005;
}

.color-text-4 {
    color: #4263eb;
}

.color-text-5 {
    color: #37b24d;
}

.color-text-6 {
    color: #7fff00;
}

.color-text-7 {
    color: #D68A59;
}

.color-text-8 {
    color: #17806D;
}

.color-text-9 {
    color: #fa5252;
}

.lead-tr td button {
    margin-bottom: -5px !important;
    font-size: 10px !important;
    padding: 1px 1px;
}

.lead-border {
    border-bottom: 1px solid whitesmoke !important;
}

.plan-body .day:last-child {
    height: 615px !important;
    display: block !important;
}

.bage-label {
    font-size: 13px;
    color: #fff;
    background: #6d9c6f;
    padding: 0px;
    width: 100%;
    display: block;
}

.padding-modal {
    padding: 10px;
}

.diet-span-list {
    font-size: 14px;
    text-transform: uppercase;
    position: relative;
    top: 22px;
    text-decoration: underline;
    color: gray;
}

.notePopupTextArea {
    width: 100%;
    height: 220px;
}

.red-text {
    color: red;
}

.report-ul li {
    text-align: left;
    font-size: 11px;
    text-transform: uppercase;
    line-height: 17px;
}

.report-ul li b {
    font-weight: bold !important;
}

.pointer {
    cursor: pointer;
}

.days-left-line {
    background-color: #91a7ff;
    color: white;
    font-weight: bold;
    font-size: 14px;
}

.days-month-line {
    background-color: #8ce99a;
    color: white;
    font-weight: bold;
    font-size: 14px;
}
.days-day-line {
  background-color: #ff9800;
  color: white;
  font-weight: bold;
  font-size: 14px;
}
.checked-line {
    background-color: #FFFF99;
}

.renew-line {
    background-color: #d3f9d8; 
}

.notrenew-line {
    background-color: #ffdeeb;
}

.blue-text {
    color: #364fc7 !important;
}

.total-row td {
    background-color: #FFFF99 !important;
}
.total-row-gray td {
  background-color: #eee !important;
}
.total-row td b,.total-row-gray td b {
    font-weight: bold !important;
}
.total-row td.dish-name{
  text-align: left !important;
}
.green-text {
    color: green;
}

.dropdown-link {
    color: white;
    cursor: pointer;
    width: 125px !important;
}

.dropdown .link-text {
    color: black;
}

.dropdown {
    background-color: #efe8eb;
    border-radius: 5px;
    padding: 15px;
    position: absolute;
    top: 48px;
    -webkit-box-shadow: 11px 17px 5px -6px rgba(201,201,201,1);
    -moz-box-shadow: 11px 17px 5px -6px rgba(201,201,201,1);
    box-shadow: 11px 17px 5px -6px rgba(201,201,201,1);
    color: black;
}

.general .review-text {
    padding-bottom: 20px !important;
    position: relative !important;
    top: -140px !important;
}

.order-history-btn {
    width: 90px;
    cursor: pointer !important;
    display: block;
    min-width: 90px !important;
    margin-right: 5px;
    margin-top: 25px;
}

.mealrow-dislikes-dish {
    color: white;
    background-color: red;
    padding: 5px;
    font-weight: bold;
}

.mealrow-likes-dish {
    color: white;
    background-color: green;
    padding: 5px;
    font-weight: bold;
}

.mail-restore-link {
   cursor: pointer;
    font-size: 12px;
    color: white;
    background: #0e7451;
    padding: 3px;
    border-radius: 3px;
}
.paystatus-info-tab {
    color: #b97de2;
}

.pointer-renew {
    cursor: pointer;
    text-decoration: underline;
}

.mail-restore-link:hover {
    color: white;
    opacity: 0.9;
}

.future-info-part {
    float: left;
    width: 60% !important;
}

.future-buttons-part {
    position: relative;
    top: 20px;
}

.select2-container {
    box-sizing: border-box;
    display: inline-block;
    margin: 0;
    position: relative;
    vertical-align: middle
}
.select2 {
    /*width: calc(100% - 45px) !important;*/
    width: 100% !important;
    /*margin-left: 45px;*/
}

.select2-container .select2-selection--single {
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    height: 28px;
    user-select: none;
    -webkit-user-select: none
}

.select2-container .select2-selection--single .select2-selection__rendered {
    display: block;
    padding-left: 8px;
    padding-right: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.select2-container .select2-selection--single .select2-selection__clear {
    position: relative
}

.select2-container[dir="rtl"] .select2-selection--single .select2-selection__rendered {
    padding-right: 8px;
    padding-left: 20px
}

.select2-container .select2-selection--multiple {
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    min-height: 32px;
    user-select: none;
    -webkit-user-select: none
}

.select2-container .select2-selection--multiple .select2-selection__rendered {
    display: inline-block;
    overflow: hidden;
    padding-left: 8px;
    text-overflow: ellipsis;
    white-space: nowrap
}

.select2-container .select2-search--inline {
    float: left
}

.select2-container .select2-search--inline .select2-search__field {
    box-sizing: border-box;
    border: none;
    font-size: 100%;
    margin-top: 5px;
    padding: 0
}

.select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
    -webkit-appearance: none
}

.select2-dropdown {
    background-color: white;
    border: 1px solid #aaa;
    border-radius: 4px;
    box-sizing: border-box;
    display: block;
    position: absolute;
    left: -100000px;
    width: 100%;
    z-index: 1051
}

.select2-results {
    display: block
}

.select2-results__options {
    list-style: none;
    margin: 0;
    padding: 0
}

.select2-results__option {
    padding: 6px;
    user-select: none;
    -webkit-user-select: none
}

.select2-results__option[aria-selected] {
    cursor: pointer
}

.select2-container--open .select2-dropdown {
    left: 0;
    width: 370px;
}

.select2-container--open .select2-dropdown--above {
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0
}

.select2-container--open .select2-dropdown--below {
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.select2-search--dropdown {
    display: block;
    padding: 4px
}

.select2-search--dropdown .select2-search__field {
    padding: 4px;
    width: 370px;
    box-sizing: border-box
}

.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
    -webkit-appearance: none
}

.select2-close-mask {
    border: 0;
    margin: 0;
    padding: 0;
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    min-height: 100%;
    min-width: 100%;
    height: auto;
    width: auto;
    opacity: 0;
    z-index: 99;
    background-color: #fff;
    filter: alpha(opacity=0)
}

.select2-hidden-accessible {
    border: 0 !important;
    clip: rect(0 0 0 0) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important
}

.select2-container--default .select2-selection--single {
    background-color: #fff;
    border: 1px solid #aaa;
    border-radius: 4px
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #444;
    line-height: 28px
}

.select2-container--default .select2-selection--single .select2-selection__clear {
    cursor: pointer;
    float: right;
    font-weight: bold
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
    color: #999
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 26px;
    position: absolute;
    top: 1px;
    right: 1px;
    width: 20px
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
    border-color: #888 transparent transparent transparent;
    border-style: solid;
    border-width: 5px 4px 0 4px;
    height: 0;
    left: 50%;
    margin-left: -4px;
    margin-top: -2px;
    position: absolute;
    top: 50%;
    width: 0
}

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__clear {
    float: left
}

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__arrow {
    left: 1px;
    right: auto
}

.select2-container--default.select2-container--disabled .select2-selection--single {
    background-color: #eee;
    cursor: default
}

.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
    display: none
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-color: transparent transparent #888 transparent;
    border-width: 0 4px 5px 4px
}

.select2-container--default .select2-selection--multiple {
    background-color: white;
    border: 1px solid #aaa;
    border-radius: 4px;
    cursor: text
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
    box-sizing: border-box;
    list-style: none;
    margin: 0;
    padding: 0 5px;
    width: 100%
}

.select2-container--default .select2-selection--multiple .select2-selection__placeholder {
    color: #999;
    margin-top: 5px;
    float: left
}

.select2-container--default .select2-selection--multiple .select2-selection__clear {
    cursor: pointer;
    float: right;
    font-weight: bold;
    margin-top: 5px;
    margin-right: 10px
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
    background-color: #e4e4e4;
    border: 1px solid #aaa;
    border-radius: 4px;
    cursor: default;
    float: left;
    margin-right: 5px;
    margin-top: 5px;
    padding: 0 5px
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
    color: #999;
    cursor: pointer;
    display: inline-block;
    font-weight: bold;
    margin-right: 2px
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
    color: #333
}

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice, .select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__placeholder, .select2-container--default[dir="rtl"] .select2-selection--multiple .select2-search--inline {
    float: right
}

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
    margin-left: 5px;
    margin-right: auto
}

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
    margin-left: 2px;
    margin-right: auto
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
    border: solid #000 1px;
    outline: 0
}

.select2-container--default.select2-container--disabled .select2-selection--multiple {
    background-color: #eee;
    cursor: default
}

.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
    display: none
}

.select2-container--default.select2-container--open.select2-container--above .select2-selection--single, .select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single, .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0
}

.select2-container--default .select2-search--dropdown .select2-search__field {
    border: 1px solid #aaa
}

.select2-container--default .select2-search--inline .select2-search__field {
    background: transparent;
    border: none;
    outline: 0;
    box-shadow: none;
    -webkit-appearance: textfield
}

.select2-container--default .select2-results > .select2-results__options {
    max-height: 200px;
    overflow-y: auto
}

.select2-container--default .select2-results__option[role=group] {
    padding: 0
}

.select2-container--default .select2-results__option[aria-disabled=true] {
    color: #999
}

.select2-container--default .select2-results__option[aria-selected=true] {
    background-color: #ddd
}

.select2-container--default .select2-results__option .select2-results__option {
    padding-left: 1em
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__group {
    padding-left: 0
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option {
    margin-left: -1em;
    padding-left: 2em
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
    margin-left: -2em;
    padding-left: 3em
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
    margin-left: -3em;
    padding-left: 4em
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
    margin-left: -4em;
    padding-left: 5em
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
    margin-left: -5em;
    padding-left: 6em
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: #5897fb;
    color: white
}

.select2-container--default .select2-results__group {
    cursor: default;
    display: block;
    padding: 6px
}

.select2-container--classic .select2-selection--single {
    background-color: #f7f7f7;
    border: 1px solid #aaa;
    border-radius: 4px;
    outline: 0;
    background-image: -webkit-linear-gradient(top, #fff 50%, #eee 100%);
    background-image: -o-linear-gradient(top, #fff 50%, #eee 100%);
    background-image: linear-gradient(to bottom, #fff 50%, #eee 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0)
}

.select2-container--classic .select2-selection--single:focus {
    border: 1px solid #5897fb
}

.select2-container--classic .select2-selection--single .select2-selection__rendered {
    color: #444;
    line-height: 28px
}

.select2-container--classic .select2-selection--single .select2-selection__clear {
    cursor: pointer;
    float: right;
    font-weight: bold;
    margin-right: 10px
}

.select2-container--classic .select2-selection--single .select2-selection__placeholder {
    color: #999
}

.select2-container--classic .select2-selection--single .select2-selection__arrow {
    background-color: #ddd;
    border: none;
    border-left: 1px solid #aaa;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    height: 26px;
    position: absolute;
    top: 1px;
    right: 1px;
    width: 20px;
    background-image: -webkit-linear-gradient(top, #eee 50%, #ccc 100%);
    background-image: -o-linear-gradient(top, #eee 50%, #ccc 100%);
    background-image: linear-gradient(to bottom, #eee 50%, #ccc 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFCCCCCC', GradientType=0)
}

.select2-container--classic .select2-selection--single .select2-selection__arrow b {
    border-color: #888 transparent transparent transparent;
    border-style: solid;
    border-width: 5px 4px 0 4px;
    height: 0;
    left: 50%;
    margin-left: -4px;
    margin-top: -2px;
    position: absolute;
    top: 50%;
    width: 0
}

.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__clear {
    float: left
}

.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__arrow {
    border: none;
    border-right: 1px solid #aaa;
    border-radius: 4px 0 0 4px;
    left: 1px;
    right: auto
}

.select2-container--classic.select2-container--open .select2-selection--single {
    border: 1px solid #5897fb
}

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow {
    background: transparent;
    border: none
}

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-color: transparent transparent #888 transparent;
    border-width: 0 4px 5px 4px
}

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--single {
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    background-image: -webkit-linear-gradient(top, #fff 0%, #eee 50%);
    background-image: -o-linear-gradient(top, #fff 0%, #eee 50%);
    background-image: linear-gradient(to bottom, #fff 0%, #eee 50%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0)
}

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--single {
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background-image: -webkit-linear-gradient(top, #eee 50%, #fff 100%);
    background-image: -o-linear-gradient(top, #eee 50%, #fff 100%);
    background-image: linear-gradient(to bottom, #eee 50%, #fff 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFFFFFFF', GradientType=0)
}

.select2-container--classic .select2-selection--multiple {
    background-color: white;
    border: 1px solid #aaa;
    border-radius: 4px;
    cursor: text;
    outline: 0
}

.select2-container--classic .select2-selection--multiple:focus {
    border: 1px solid #5897fb
}

.select2-container--classic .select2-selection--multiple .select2-selection__rendered {
    list-style: none;
    margin: 0;
    padding: 0 5px
}

.select2-container--classic .select2-selection--multiple .select2-selection__clear {
    display: none
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice {
    background-color: #e4e4e4;
    border: 1px solid #aaa;
    border-radius: 4px;
    cursor: default;
    float: left;
    margin-right: 5px;
    margin-top: 5px;
    padding: 0 5px
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove {
    color: #888;
    cursor: pointer;
    display: inline-block;
    font-weight: bold;
    margin-right: 2px
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove:hover {
    color: #555
}

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
    float: right
}

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
    margin-left: 5px;
    margin-right: auto
}

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
    margin-left: 2px;
    margin-right: auto
}

.select2-container--classic.select2-container--open .select2-selection--multiple {
    border: 1px solid #5897fb
}

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--multiple {
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--multiple {
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0
}

.select2-search__field {
    display: block;
}

.select2-container--classic .select2-search--dropdown .select2-search__field {
    border: 1px solid #aaa;
    outline: 0
}

.select2-container--classic .select2-search--inline .select2-search__field {
    outline: 0;
    box-shadow: none
}

.select2-container--classic .select2-dropdown {
    background-color: #fff;
    border: 1px solid transparent
}

.select2-container--classic .select2-dropdown--above {
    border-bottom: none
}

.select2-container--classic .select2-dropdown--below {
    border-top: none
}

.select2-container--classic .select2-results > .select2-results__options {
    max-height: 200px;
    overflow-y: auto
}

.select2-container--classic .select2-results__option[role=group] {
    padding: 0
}

.select2-container--classic .select2-results__option[aria-disabled=true] {
    color: grey
}

.select2-container--classic .select2-results__option--highlighted[aria-selected] {
    background-color: #3875d7;
    color: #fff
}

.select2-container--classic .select2-results__group {
    cursor: default;
    display: block;
    padding: 6px
}

.select2-container--classic.select2-container--open .select2-dropdown {
    border-color: #5897fb
}




.select2-container--default .select2-selection--multiple, .select2-container--default .select2-selection--single, .select2-container--default.select2-container--focus .select2-selection--multiple {
    height: 46px;
    border: none;
    border-bottom: 1px solid #9e9e9e;
    border-radius: 0;
    outline: 0
}

.select2-container--default .select2-selection--multiple, .select2-container--default.select2-container--focus .select2-selection--multiple {
    height: auto
}

.select2-container--default .select2-search--inline .select2-search__field {
    height: 50px
}

.select2-container--default .select2-selection--multiple input {
    margin: 0
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
    border: none;
    color: #fff;
    margin-top: 8px;
    padding: 3px 10px;
    background-color: #42A5F5
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove, .select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
    color: #fff;
    margin-right: 5px
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 45px;
    padding-left: 0
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 40px
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
    border-color: gray transparent transparent;
    border-width: 5px 5px 0;
    margin-left: -5px;
}

.select2-container--open .select2-dropdown--above, .select2-container--open .select2-dropdown--below {
    border: none;
    box-shadow: 0 1px 2px rgba(0, 0, 0, .26)
}

.select2-results__option {
    padding: 1rem
}

.select2-container--default .select2-search--dropdown .select2-search__field {
    border-top: none;
    border-right: none;
    border-left: none
}

.select2-container--default .select2-results__option--highlighted[aria-selected], div.tagsinput span.tag {
    background-color: #42A5F5
}

.select2  + label {
    position: absolute;
    top: -26px;
    font-size: 0.8rem;
}

/* heading */
h3 {
  margin: 0 !important;
  text-transform: uppercase;
}

.add-meals {
  font-size: 12px;
  margin-top: 7px;
  display: block;
  color: #9c27b0;
}

.progress-block {
  margin-bottom: 45px !important;
  float: left;
  margin-right: 20px !important;
  width: 120px;
  margin-left: 20px !important;
  opacity: 0.8;
}

.additional-list div:first-child {
  margin-left: 0px !important;
}

.additional-list div {
  margin-top: 15px;
  margin-bottom: 15px;
  border-top: 1px solid #599099;
  width: 430px;
}

.complimentarySide {
  width: 100%;
}

.additional-edit {
  font-size: 12px;
  margin-right: 10px;
  text-transform: lowercase;
  cursor: pointer;
  color: #599099;
}

.additional-edit:hover {
  color: #9c27b0;
  text-decoration: underline;
}

.subform-text {
  width: 405px;
  font-size: 13px;
}

.additional-remove {
  cursor: pointer;
  padding: 2px 5px;
  font-size: 11px;
  position: relative;
  top: 5px;
  margin-right: 5px;
}

.additional-remove:hover {
  color: white;
}

.subform-success-text {
  color: green;
  display: block;
  width: 600px;
  margin-top: 5px;
  margin-bottom: -10px;
}

.subform-btn {
  width: 195px;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
  border-radius: 3px;
  float: right;
}

.pausedday {
  background: #ffa8a8 !important;
  color: white !important;
  text-transform: uppercase;
  padding: 2px !important;
  font-size: 9px !important;  
}

.movedcopyday {
  background: #599099 !important;
  color: white !important;
  text-transform: uppercase;
  padding: 2px !important;
  font-size: 9px !important;
}

.movedby, .movedreason {
  font-size: 10px !important;
  color: #599099 !important;
  padding: 0px !important;
  margin-top: 5px;
}

.movedby {
   margin-left: -10px;
}

.movedreason { 
  margin-left: 0px;
}

.progress-block-big {
  width: 100%;
}

.userPic {
  max-width: 80px;
  max-height: 80px;
}

.progress-block-big .before, .progress-block-big .after {
  text-align: center;
  float: left;
  width: 50%;
}

.progress-block-big .before img {
    max-width: 300px;
}

.progress-block-big .after img {
    max-width: 300px;   
}

.progress-block-history {
  font-size: 20px;
  margin-left: 15px !important;
  margin-top: 20px !important;
  margin-bottom: 9px !important;
}

.progress-block:hover {
  opacity: 1;
  cursor: pointer;
}

.progress-block .date {
  font-weight: 400;
  text-transform: uppercase;
  font-size: 12px;
  position: relative;
  top: 120px;
  margin-left: 25px;
}

.progress-block .before img {
    max-width: 55px;
    float: left;
}

.progress-block .after img {
    max-width: 55px;   
    float: right;
}

.future-info-part {
  padding: 20px;
}

.daysleft {
  color: #f44336;
  float: right;
  margin-right: 5px;
  margin-left: 17px;
}

.paystatus {
    color: #f44336;
    font-size: 12px;
    float: right;
    margin-right: 5px;
}

.tileStatuses {
    float: right;
    width: 150px;
}

.tileStatuses {
  float: right;
  width: 150px;
}
.daysleft.green, .reason.green, .paystatus.green {
  color: #2c9b7f;
}

.daysleft.yellow, .reason.yellow, .paystatus.yellow {
  color: #ff9800;
}

.fileUpload {
  margin: 15px 20px !important;
}

.all-reasons-list {
  position: relative;
  top: 20px;
  left: 20px;
  text-transform: uppercase;
  font-size: 14px;
  cursor: pointer;
}

.file-download {
  cursor: pointer;
  text-decoration: underline;
}

.whiteArea {
  background-color: white;
  padding: 25px;
}

.Tabled {
  width: 100%;
  margin-top: 20px;
  text-align: center;
}

.files-head {
  font-weight: 700;
  text-transform: uppercase;
  color: gray;
  margin-left: 25px;
}

.Tabled-small {
  width: 96%;
  margin: 15px 20px;
  text-align: center;
}

.Tabled td, .Tabled th {
  padding: 15px 10px;
  text-align: center !important;
  border: none !important;
}

.reasonTable td, reasonTable th {
    border: 1px solid silver !important;
    vertical-align: top !important;
}

.reasonTable td.notelist {
    text-align: left !important;
}

.Tabled-small td, .Tabled-small th {
  padding: 5px 3px;
  text-align: center !important;
  border: none !important;
}

.mealrow-meals {
  height: 470px;
  padding: 15px;
  overflow-x: scroll;
}

.mealrow-allergy {
  color: #f44336 !important;
  font-size: 13px;
  line-height: 17px;
  margin-top: 11px;
}

.mealrow-note-p {
  min-height: 25px;
}

.commentSide {
  text-align: center;
  margin-top: 12px;
}

.commentSide input {
  margin-bottom: 8px;
  border-radius: 0;
  padding: 6px;
  color: #354156 !important;
  border: 1px solid silver !important;
}

.commentSide .btn-note {
  border-radius: 0px;
  background-color: #90aa9e;
  color: white;
  padding: 8px;
  font-size: 14px;
  cursor: pointer;
}

.commentSide .btn-note:hover {
  opacity: 0.8;
}

.mealrow-dislikes {
  color: #ff9800 !important;
  font-size: 13px;
  line-height: 17px;
  margin-top: 11px;
}

.mealrow-allergy b {
  color: #cf56e5 !important;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
}

.allergy-notice-warning {
  color: #ff9800 !important;
}

.mealrow-calories-summary {
  color: #354156 !important;
}

.mealrow-finish-btn {
  float: right;
  background-color: #e2cfa9 !important;
  border-color: #e2cfa9 !important;
  cursor: pointer;
}

.mealrow-active-type, .mealrow-active-meal {
  background-color: #354156 !important;
  border-color: #354156 !important;
  color: white !important;
}

.mealrow-active-meal .mealrow-cho, .mealrow-active-meal h4 {
  color: white !important;
}

.mealrow-date {
  color: #414e65;
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 0;
  letter-spacing: .05em;
  margin-top: .9rem;
  border-bottom: 1px solid #f3f3f3;
  padding-bottom: .5rem;
}

.mealrow-note-value {
  font-size: 13px;
  color: #90aa9e;
  font-weight: bold;
}

.mealrow-h5 {
  font-size: 20px;
  padding: 25px 0 .5rem;
  color: #414e65;
  margin: 0;
  position: relative;
  margin-left: 20px;
}

.mealrow-item {
  position: relative;
  border: 1px solid #f4f4f4;
  width: 240px;
  height: 460px;
  padding: 10px;
  overflow: hidden;
  margin-right: 15px;
  margin-bottom: 5px;
  border-radius: 2px;
  box-shadow: 0 1px 5px rgba(0,0,0,.02);
  background: #fff;
  float: left;
  font-size: 12px;
  line-height: 14px;
}

.mealrow-item h4 {
  font-size: 13px;
  font-weight: 600;
  text-transform: capitalize;
  text-align: left;
  line-height: 20px;
  letter-spacing: .6px;
  margin-top: 0;
  margin-bottom: 0;
  color: #414d65;
  min-height: 20px;
  margin-top: 13px;
}

.mealrow-realname {
  min-height: 18px;
}

.mealrow-img {
  max-width: 100%;
  height: 140px;
  margin: 0 auto;
  background-position: 50%;
  background-size: cover;
  width: 100%;
}

.mealrow-area {
  background-color: #ffffff;
  padding: 20px;
}

.mealrow-day .mealrow-day-header {
    border-bottom: 1px solid #f3f3f3;
    padding: 10px 20px;
}

.mealrow-dish-groups li.can-change {
    background: #fff;
    color: #90aa9e;
    cursor: auto;
    text-transform: none;
    border: 1px solid #f8f7f7;
}

.mealrow-dish-groups li {
    display: inline-block;
    font-size: 12px;
    letter-spacing: .1em;
    padding: 5px 11px;
    font-weight: 700;
    border-radius: 20px;
    border: 1px solid #f8f7f7;
    margin-right: .5rem;
    cursor: pointer;
    text-transform: uppercase;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    margin-bottom: 5px;
    background: #90aa9e;
    color: #fff;
}

.mealrow-dish-groups {
  padding: 10px 0px 0px 0px;
}

.mealrow-cho {
  font-family: Lato,sans-serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0;
  line-height: 1.8;
  color: #696969;
  font-size: .8rem !important;
}

.mealrow-type {
  color: #648375;
  font-size: 18px;
  margin: 0;
  font-weight: bolder;
  font-weight: bold;
}


.userrow-header {
  margin-top: 30px !important;
  margin-bottom: 10px !important;
  font-size: 22px;
}

.td-user-url {
  cursor: pointer;
}

.table-user {
  width: 100%;
  display: table;
  overflow: hidden;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  border-spacing: 0;
  border-collapse: collapse;
}

.table-user td {
  padding: 10px;
}

.table-user .table-head td {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: bold;
}


.birthday-btn {
  float: right;
  margin-right: 17px;
}

/* heading  end*/
/* model */
.model ~ button {
  padding: 12px 30px;
}

.modalrow {
  cursor: pointer;
}

.modalrow:hover {
  background: #ededed;
}

.add-note-text {
  color: #f44336;
  min-height: 25px;
}

.input-notes button {
  padding: 0px !important;
}

.disabled {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

.whiteRow {
  background: #fff;
}

.notes-date-side {
  font-size: 10px;
  color: #999;
  text-transform: uppercase;
}

.whiteRow td {
  padding: 10px !important;
}

.whiteRow td:last-child {
  text-align: right;
  display: table-cell;
}
.d-flex {
    display: flex;
    flex-direction: row;
}
.mb-10 {
    margin-bottom: 10px;
}
.ml-10 {
    margin-left: 10px;
}
.mr-10 {
    margin-right: 10px;
}
.pt-20{
    padding-top: 20px;
}

.space-between{
    justify-content: space-between;
}
.end-flex{
    justify-content: flex-end;
}
.whiteRow td:last-child button {
  display: inline;
}

/* model end */
/* filter */
.filtesHolder > div {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.18), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.1);
}

.FiltersOnModel {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .18), 0 3px 1px -2px rgba(0, 0, 0, .12), 0 1px 5px 0 rgba(0, 0, 0, .1);
  margin-bottom: 3px !important;
}

.FiltersOnModel tr td {
  background: #fff;
}

.FiltersOnModel tr td:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  padding-left: 26px;
}

.FiltersOnModel tr td:last-child {
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}

.fix-on-filters div label + div {
  line-height: 1.3875em;
}

.fix-on-filters > div {
  margin-right: 10px !important;
    max-width: 17%;
}

.fix-on-filters > div label {
  font-size: 13px;
  line-height: 1;
}

/* filter end */
/* dish form */
.checkHolder {
  margin-top: 10px;
}

.checked {
  color: green;
  border: 1px solid green !important;
  border-radius: 3px;
}

.checked-noborder {
  color: green;
  border: none !important;
  border-radius: 3px;
}

.unChecked {
  color: #cccccc;
  border: 1px solid #cccccc;
  border-radius: 3px;
}

.checked-radio {
  color: green;
}

.unchecked-radio {
  color: #cccccc;
}

.dishForm .inputHolder {
  margin-bottom: 10px;
  margin-top: 5px;
}

.dishForm .drop-zone {
  margin-top: 30px;
  border-radius: 5px;
}

.dishForm textarea {
  font-size: 14px !important;
}

.descOnDishForm {
  font-size: 12px;
  color: #888;
  margin: 0;
  line-height: 13px;
}

.qtyHolder {
  padding-top: 15px;
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  min-height: 32px;
}

.qtyHolder p {
  margin: 5px 0;
}

.qtyHolder .label {
  position: absolute;
  font-size: 12px;
  top: -5px;
}

.descOnDishForm span {
  color: #cd3fa0;
}

/* dish form end */
.Select--single {
  padding-top: 12px;
}

.Select-control {
  border-radius: 0 !important;
  border-bottom: 1px solid #ccc !important;
  border-left: none !important;
  border-right: none !important;
  border-top: none !important;
}

/*tabs like on dishes */

.tabsCustom {
  position: fixed;
  left: 15px;
    right: 15px;
  width: calc(100% - 30px);
  top: 50px;
  padding: 0px!important;
  z-index: 10;
  box-sizing: border-box;
  background: #eee;
}
.tabsCustom > div {
    padding: 0 !important;
}
.tabsCustom div button{
    margin: 5px 1px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.27);
    width: 110px;
    font-size: 9px !important;
}
.tabsCustom.menubuilder div button{
  width: auto;
}
.dishes div {
  border-bottom: 1px solid #eeeeee;
}

.dishes {
  width: 33%;
  flex-basis: 33%;
  flex: 0 0 auto;
  margin: 0;
  box-sizing: border-box;
  padding: 0 15px !important;
  position: fixed;
  top: 230px;
  right: 30px;
  height: calc(100vh - 240px);
  overflow-y: auto;
  z-index: 9;
  border-top-right-radius: 0px !important;
  border-top-left-radius: 0px !important;
}

.dishes-head {
  width: 33%;
  flex-basis: 33%;
  flex: 0 0 auto;
  margin: 0;
  box-sizing: border-box;
  padding: 0 10px !important;
  margin: 0 -15px;
  padding: 10px 15px;
  border-bottom: 1px solid #cecece;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .14);
  background: #dfdfdf;
  position: fixed;
    top: 196px;
    right: 30px;
  height: 35px !important;
  height: calc(100vh - 305px);
  overflow-y: auto;
  z-index: 9;
  border-top-right-radius: 3px !important;
  border-top-left-radius: 3px !important;
  font-size: 15px;
  font-weight: bold;
  line-height: 30px;
}

.afterTabsCustom {
  padding-top: 85px !important;
  margin-top: -35px;
  width: 100%;
}
.afterTabsCustom > div {
    padding-left: 0 !important;
    padding-right: 0 !important;
}
/*tabs like on dishes */
/*programs*/
.holderWithBg {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  background: #fff;
  border-radius: 3px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 15px;
  padding-top: 5px;
  margin: 0 -15px;
  margin-bottom: 5px;
}

.programsHeading .head.sub {
  background: #fff;
}

.programsHeading .head {
  width: 100%;
  color: rgba(0, 0, 0, 0.87);
  display: inline-block;
  position: relative;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  background: #dfdfdf;
  border-radius: 3px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 5px;
}

.holder {
  position: relative;
}

.holder .day {
  overflow: auto;
  white-space: nowrap;
  margin: 0 -15px 0 -15px;
  padding-top: 15px;
  min-height: 237px;
}

.holder .left {
  position: absolute;
  background: white;
  width: 18px;
  padding: 0;
  z-index: 9;
  left: -15px;
  margin: 0;
  bottom: 0;
  top: 40px;
}

.holder h5 {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 700;
  padding-left: 3px;
  width: calc(70% - 3px);
  display: inline-block;
  color: #6d9c6f;
}

.holder .edit-button-holder {
  width: 30%;
  text-align: right;
  display: inline-block;
  margin: 5px 0;
}

.holder .edit-button-holder.active button {
  background: #6d9c6f;
}

.holderWithBg.week.active .holder .day .dish .del {
  display: block;
}

.day .empty {
  border: 2px dashed #f1f1f1 !important;
  width: 160px;
  padding: 15px;
  font-size: 14px;
  font-weight: 500;
  color: #b7b7b7;
  text-align: center;
  position: absolute;
  top: 30px;
  left: 5px;
  white-space: normal;
  line-height: 20px;
}

.holderWithBg.week {
  border: 2px solid #ffffff;
}

.holderWithBg.week:first-child {
    margin-top: 90px;
}

.holderWithBg.week.active {
  border: 1px solid #999999;
}

.collection {
  display: block;
  font-weight: 700;
  font-size: 14px;
  color: #ff9800;
}

.holder .edit-button-holder button {
  padding: 0px 5px;
  margin: 0;
  min-height: 30px;
}

.holder .right {
  position: absolute;
  background: white;
  width: 18px;
  padding: 0;
  z-index: 9;
  right: -15px;
  margin: 0;
  bottom: 0;
  top: 40px;
}

.dishes {
  padding: 0px !important;
  padding-top: 30px;
}

.dishes .day {
  max-width: 100%;
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.dishes .day .dish {
  width: 100%;
  border: none;
  padding: 0;
  margin: 0;
  text-align: left;
  padding: 15px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  padding-left: 55px;
}

.dishes .day .dish .title,
.dishes .day .dish .cal {
  text-align: left;
  height: auto;
}

.dishes .day .dish .image {
  width: 100px;
  height: 80px;
  display: inline-block;
  margin-right: 10px;
  float: left;
}

.day .dish {
  width: 160px;
  display: inline-block;
  text-decoration: none;
  margin-left: 12px;
  border: 1px solid #cecece;
  border-radius: 3px;
  text-align: center;
  padding-bottom: 10px;
  cursor: pointer;
  position: relative;
}

.day .dish .del {
  display: none;
  height: 25px;
  width: 26px;
  text-align: center;
  position: absolute;
  top: -10px;
  right: -10px;
  margin: 0;
  background: #fff;
  border-radius: 50%;
  padding-top: 1px;
  color: #c84e6e;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .14);
  border: 1px solid #e6e6e6;
}

.dish .to-list {
  display: block;
  height: 25px;
  width: 26px;
  text-align: center;
  position: absolute;
  background: #6d9c6f;
  border-radius: 50%;
  padding-top: 1px;
  color: #fff;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .14);
  top: 50%;
  margin-top: -13px;
  left: 15px;
}

.day .dish .del:hover {
  background: #354156;
  color: #fff;
  border: 1px solid #354156;
}

.day div:first-child {
  margin-left: 18px;
}

.day div:last-child {
  margin-right: 30px;
}

.click-to-edit {
  border: 2px dashed #f1f1f1 !important;
  width: 150px;
  padding: 40px 15px;
  font-size: 14px;
  font-weight: 700;
  color: #b7b7b7;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -95px;
  margin-top: -105px;
}

.click-to-edit.no-dishes {
  color: #f19f27;
}

.day .dish .image {
  margin: 0;
  height: 110px;
  width: 100%;
  background: #72867d;
  background-size: cover;
  background-position: center;
}

.day .dish .title {
  font-size: 14px;
  font-weight: normal;
  margin: 0;
  padding: 5px;
  white-space: initial;
  height: 50px;
  line-height: 14px;
}

.plan-type-label {
  position: absolute;
  bottom: 7px;
  right: 15px;
  text-align: right;
}

.plan-type-description,
.plan-type-head {
  text-align: right;
  margin: 0;
}

.plan-type-description {
  font-size: 14px;
  color: #696969;
  font-weight: 400;
  line-height: 16px;
}

.plan-type-head {
  font-weight: 600;
  cursor: pointer;
}

.plan-type-head:before {
  content: "?";
  background: #6d9c6f;
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  line-height: 21px;
  color: #fff;
  text-align: center;
  margin-right: 5px;
  font-size: 14px;
}

.day .dish .group,
.day .dish .cal {
  margin: 0;
  font-size: 14px;
  font-weight: bold;
  color: #6d9c6f;
}

.day .dish .group {
  color: #9c27b0;
}

.day .dish .rating {
  margin: 0;
}

.programsBody .programsHeading {
  top: 99px;
}

.programsBody {
  padding-top: 96px;
}


.programsHeading {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0px 15px;
  position: fixed;
  left: 0;
  width: 100%;
  z-index: 10;
}

.head > button {
  padding: 5px;
  width: 160px;
  margin: 3px;
  white-space: initial;
}

.head.sub > div button {
  padding: 5px;
  min-width: 100px;
  width: auto;
  margin: 3px;
  white-space: initial;
}

/*end programs*/
.image-preview-320 {
  height: 36px;
  width: auto;
  max-width: 200px;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% auto;
}

.not-found p {
  text-align: center;
  font-size: 6em;
  padding: 0;
  line-height: 1em;
}

.scroll-wrapper {
    overflow: auto;
}

.modal-button-handler {
  display: flex;
}

.modal-close-button {
  position: absolute;
  right: 5px;
  top: 5px;
  padding: 5px;
  z-index: 10;
  cursor: pointer;
  color: #b8b8b8;
}

.modal-close-button svg g path{
    fill: #282f3f;
}

.right {
  right: 0;
}

.week-days {
  list-style-type: none;
  user-select: none;
}

.week-days li {
  display: inline-block;
  border: 1px solid #e4ebee;
  background: #fff;
  width: 2rem;
  text-align: center;
  transition: all 0.2s;
  cursor: pointer;
  margin-left: -1px;
  height: 1.5em;
  padding: 1px;
}

.week-days li:first-child {
  border-top-left-radius: .3rem;
  border-bottom-left-radius: .3rem;
  margin-left: 0;
}

.week-days li:last-child {
  border-top-right-radius: .3rem;
  border-bottom-right-radius: .3rem;
}

.week-days li:hover {
  background: #e5ffd7;
}

.week-days li.active-day {
  background: #03c249;
  color: #fff;
}

.prices-table {
  width: 100%;
}

.prices-table tr {
  line-height: 2em;
}

.prices-table thead td {
  text-align: center;
}

.prices-table .first-col {
  text-align: left !important;
  align-content: left !important;
}

.prices-table tbody td {
  text-align: center;
  align-content: center;
}

.editor-work-area {
  height: 500px !important;
  border: 1px solid #bebebe;
  background: #fff;
  padding: 10px;
}

.drop-zone-label {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  user-select: none;
}
.customer-item .customer-info .map-holder{
    height: 100% !important;
}
.drop-zone-label > p {
  position: absolute;
  top: 50%;
  text-align: center;
  transform: translateY(-50%);
  padding: 0;
  margin: 0 auto;
  font-size: 18px;
  color: #8a8a8a;
  width: 100%;
}

.drop-zone {
  height: 200px;
  width: 100%;
  margin: 0 auto;
  max-width: 400px;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% auto;
  border: 1px dashed #a7a7a7;
}

.users-list {
  height: 80vh;
  margin: 15px;
  top: 0;
  left: 0;
  right: 0;
  overflow-y: scroll;
}

.users-list .list-item {
  border: none;
  width: calc(100% - 1px);
  text-align: left;
  margin: 1px;
  height: 64px;
  background: #ffffff;
  color: #354156;
  display: block;
}

.users-list .list-item-active {
  background: #354156;
  color: #ffffff;
}

.roundedHeader tr th {
  background-color: #354156;
  color: #fff;
}

.roundedHeader.mini tr th {
  background-color: #eee;;
  padding: 10px 15px;
  color: #53597f;
  font-weight: bold;
}

.roundedHeader.mini tr {
  height: 32px;
}

.date-date {
  text-align: center;
  text-transform: uppercase;
  background: #46566e;
  border-radius: 5px;
  color: #fff;
  font-weight: bold;
  padding: 5px;
}

.roundedHeader tr th:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  padding-left: 26px;
}

.roundedHeader tr th:last-child {
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  padding-right: 26px;
  text-align: right;
}

.settings button {
  display: inline-block;
  width: 100%;
  padding: 20px;
}

/*prices*/
.prices-table input {
  width: 80px !important;
}

.prices-table tr {
  line-height: 3em;
}

.prices-table thead tr {
  background: #fff !important;
}

.prices-table tr:nth-child(2n-1) {
  background: #efefefee;
}

.prices-table tr td {
  padding: 5px;
}

/* customers */
.customer-wrap {
  padding-top: 131px;
  min-height: calc(100vh - 200px);
}
.customer-wrap .customers-inner > div,
.customer-wrap .customers-inner{
  padding-top: 0px;
  margin-top: 0;
}

.package-board .top-position,
.customer-wrap .top-position {
  position: fixed;
  top: 105px;
  left: 0;
  z-index: 999;
  margin: 0;
  padding: 0 15px;
  padding-left: 13px;
  width: 100%;
  /* box-sizing: border-box; */
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.customer-wrap .top-position > div {
    box-shadow: none !important;
    background: #eee!important;
}
.customer-wrap .top-position > div > div {
    box-shadow: none !important;
}
.customer-wrap .top-position .tabsCustom {
  padding: 5px 15px 0 15px !important;
    display: flex;
    background: #eee;
}

.customer-item {
    position: fixed;
    right: 27px;
    top: 182px;
    height: calc(100vh - 190px);
    z-index: 999;
    flex-basis: calc(50% - -120px);
    width: calc(50% - -120px);
}

.customer-item > div {
  margin-bottom: 1px;
  margin-top: 0;
}
.customer-item > div > div{
  margin-bottom: 0;
  margin-top: 0;
}

/*.my-pins div div div div{
    overflow: auto;
    white-space: nowrap;
    padding-left: 50px;
}*/
.my-pins div div div div button {
  padding: 7px 5px 7px 0 !important;
  margin: 8px 0 5px 0 !important;
  margin-right: 3px !important;
}
.top-buttons-inner{
    width: auto;
}
.my-pins {
    width: calc(100% - 560px);
    height: 42px;
    overflow: hidden;
    display: flex;
    box-shadow: 0 1px 2px rgba(0,0,0,.27);
}
.my-pins .pins-outher > span {
    line-height: 40px;
    padding: 0 10px;
    color: #2b3d4e;
    font-size: 13px;
    font-weight: bold;
}
.my-pins .pins-outher > div button {
    margin-right: 5px;
    padding: 2px 0px;
    margin-top: 0;
    margin-bottom: 0;
    height: 35px;
}
.my-pins .pins-outher > div button .item-title{
    max-width: 90px;
    overflow: hidden;
}
.my-pins .pins-outher {
    display: flex;
    height: 46px !important;
    height: 40px;
    width: calc(100% - 40px);
    padding-right: 25px;
    overflow: hidden;
}
.my-pins .pins-outher .pins-inner{
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
}
.my-pins .pins-outher .pins-inner button{
    flex: 0 0 auto;
}
.customer-wrap .top-position .tabsCustom .my-pins{
    padding: 5px 0 !important;
    margin-left: 5px;
}
.my-pins > div {
  height: 55px;

}

.my-pins button svg {
  font-size: 16px;
  padding: 0 5px;
}

.my-pins button .pin-inner svg {
  font-size: 20px;
  padding: 0 2px 0 5px;
}

.remove {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.28), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  background-color: #354156;
  display: inline-block;
  border-radius: 3px;
  padding: 5px;
  line-height: 15px;
  position: absolute;
  right: 15px;
  top: 11px;
  cursor: pointer;
}

.remove svg {
  color: #ffffff;
  padding: 0;
}

.customer-tabs > div > div {
  margin: -3px 0 0 0;
  padding: 0;
  padding-bottom: 0!important;
  box-shadow: 0 1px 2px rgba(0,0,0,.27);
}
.customer-tabs > div > div  div {
    min-height: auto;
}
.customer-plan,
.customer-info {
  height: calc(100vh - 295px);
}

.customer-tabs > div > div:last-child {
  margin: 0;
}

.customer-tabs > div > div:last-child > div > div > div {
  margin: 0;
  padding: 0 !important;
}

.customer-tabs > div > div > div > div > div > div button {
    text-align: center;
    padding: 0 10px;
    height: 30px;
    width: auto;
    min-width: auto;
}

.customer-tabs > div > div > div > div > div > div button span {
  padding: 0;
  display: inline;
  margin: 0;
}

.customer-head h4 {
  margin: 0;
  font-weight: bold;
  padding: 8px 0;
}

.customer-info > div > div {
  border-bottom: 1px solid #eee;
  padding: 10px 0 !important;
}

.customer-info > div > div,
.customer-info > div > div > div,
.customer-info > div > div > div > div {
  position: relative;
}

.default-ad {
  font-size: 12px;
  background: #78b340;
  padding: 1px 3px;
  border-radius: 3px;
  line-height: 14px;
  margin-left: 10px;
  color: #fff;
  /*  display: block;
    width: max-content;*/
}

.customer-info button {
  z-index: 2;
  height: 23px !important;
  width: 23px !important;
  min-width: 23px !important;

}

.customer-info button svg {
  font-size: 16px !important;
  padding-top: 3px;
}

.customer-info p {
  margin-bottom: 5px;
}

.customer-head button {
  padding: 3px 8px;
  min-width: 20px;
  min-height: 10px;
  margin: 0;
  margin-right: 5px;
}

.customer-head button svg {
  font-size: 16px;
}

/* end customers */
/*scroll*/
.scrollable-v,
.scrollable-h {
  overflow: auto;
}

.scrollable-v {

}

.my-pins div div .MuiGrid-typeContainer-70 {
  padding-right: 10px;
}

.MuiGrid-typeItem-71.MuiGrid-grid-md-11-132.ItemGrid-grid-160 {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding: 0 3px !important;
  margin: 0 -3px;
}

.MuiGrid-typeItem-71.MuiGrid-grid-md-11-132.ItemGrid-grid-160 button {
  flex: 0 0 auto;
}

.MuiGrid-typeItem-71.MuiGrid-grid-md-11-132.ItemGrid-grid-160::-webkit-scrollbar,
.scroll-wrapper::-webkit-scrollbar,
.scrollable-v::-webkit-scrollbar,
.scrollable-h::-webkit-scrollbar {
  width: 5px;
}
.scrollable-v::-webkit-scrollbar{
    height: 5px;
}
.MuiGrid-typeItem-71.MuiGrid-grid-md-11-132.ItemGrid-grid-160::-webkit-scrollbar-track,
.scroll-wrapper::-webkit-scrollbar-track,
.scrollable-v::-webkit-scrollbar-track,
.scrollable-h::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
}

.MuiGrid-typeItem-71.MuiGrid-grid-md-11-132.ItemGrid-grid-160::-webkit-scrollbar-thumb,
.scroll-wrapper::-webkit-scrollbar-thumb,
.scrollable-v::-webkit-scrollbar-thumb,
.scrollable-h::-webkit-scrollbar-thumb {
  background-color: #b0b0b0;
  outline: 1px solid #b0b0b0;
}

.active-customer-item {
  /* border: 1px solid #beb9b9; */
  background: #344159;
  width: 100%;
  box-shadow: 0px 2px 7px #2f394e4d;
}
.banned {
  /* border: 1px solid #beb9b9; */
  background:red;
  width: 100%;
  box-shadow: 0px 2px 7px #2f394e4d;
}
.customer-item-empty.on-list{
    left: unset;
    right: unset;
    position: relative;
    width: inherit;
    margin: 0 15px;
}
.customer-item-empty {
    width: calc(50% - -90px);
    color: rgba(0,0,0,.87);
    padding: 15px;
    display: inline-block;
    right: 25px;
    position: fixed;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
    background: #fff;
    border-radius: 3px;
}
.customer-item-empty p.to-arrow{
    font-size: 32px;
    margin: 0;
    color: #88b08a;
}
.finances,
.finances .fix-on-filters{
    display: flex;
    width: 100%;
}
.finances .buttons-on-filters,
.finances .fix-on-filters .buttons-on-filters{
    display: flex;
    width: auto !important;
    margin-left: auto;
    margin-right: 0 !important;
    align-items: center;
    justify-content: flex-end;
}
.finances .buttons-on-filters button,
.finances .fix-on-filters .buttons-on-filters button{
    position: relative !important;
    top: unset !important;
    right: unset !important;
}
.regular,
.active-customer-item,.banned {
  padding: 15px;
  border-radius: 5px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.active-customer-item .customer-desc,.banned .customer-desc {
  border-left: 1px solid #ab47bc;
}
.banned .customer-desc .location,.banned .id,.banned .customer-desc .phone{
  color: #ffffff;
}
.banned .id,.banned .customer-desc .phone{
  font-weight: normal;
}
.customer-plan .plan-head .button-part.disable div {
  background-color: #b3afaf;
}
.regular p,
.active-customer-item p ,.banned p{
  margin: 0;
}

.bold {
  /*font-weight: bold;*/
}

.local-root,
.local-root > div {
  margin-bottom: 0;
}

.customer-owerview {
  display: inline-block;
  vertical-align: top;
  padding-top: 4px;
  margin-right: 10px;
  /*border-right: 1px solid #d5d5d5;*/
}

.customer-desc {
  display: inline-block;
  width: calc(100% - 165px);
  padding-left: 10px;
  border-left: 1px solid #d5d5d5;
}

.customer-desc p {
  margin: 0;
  width: 100%;
  line-height: 24px;
}

.customer-desc .name,
.customer-desc .age-gender,
.customer-desc .phone {
  display: inline-block;
    line-height: 1;
}

.customer-desc .plan,
.active-customer-item .id,
.customer-desc .plan,
.active-customer-item .customer-desc .phone {
  color: #b965ca;
  font-weight: normal;
}

.customer-desc .name {
  width: 65%;
  font-size: 16px;
  font-weight: 400;
  word-wrap: break-word;
}

.customer-desc .age-gender {
  width: 100%;
  font-size: 12px;
  text-align: right;
  padding-right: 5px;
  padding-bottom: 5px;
  line-height: 12px;
}

.active-customer-item .name ,.banned .name{
  color: #eeeeee;
}

.active-customer-item .customer-owerview p,
.active-customer-item .customer-desc .age-gender,
.active-customer-item .pinfo .types,
.active-customer-item .customer-owerview .duration {
  color: #d3d3d3;
  font-weight: normal;
}
.banned .customer-owerview p,.banned .customer-desc .age-gender,.banned .pinfo .types,.banned .customer-owerview .duration{
  color: #fff;
}
.progress h5.green {
  margin:0px !important;
    padding-right: 10px;
}

.progress {
    height: calc(100vh - 325px);
    padding: 15px;
}

.customer-desc .phone {
  width: 34%;
  font-size: 13px;
  color: #59875b;
  text-align: right;
  font-weight: 400;
    line-height: 1;
}

.customer-desc .location {
  font-size: 13px;
  font-weight: 400;
    line-height: 1;
}

.customer-desc .location {
  color: #a2a2a2;
}

.customer-desc .plan {
  font-size: 14px;
  font-weight: 400;
    line-height: 1.05;
}

.customer-owerview .status,
.customer-owerview .id,
.customer-owerview .duration {
  font-size: 14px;
  line-height: 18px;
}

.customer-owerview .duration {
  font-weight: 700;
}

.customer-owerview .id {
  font-weight: 400;
}

.customer-owerview .duration {
  margin-bottom: 2px;
}

.pinfo {
  line-height: 18px;
  margin-top: 5px;
}

.pinfo .types {
  font-size: 14px;
  font-weight: 400;
}

.customer-desc .age-gender,
.customer-owerview .duration {
  color: #555;
}

.customer-owerview .price {
  color: #178d1c;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
}

.customer-owerview .status.paid {
  color: #6d9c6f;
}

.customer-owerview p {
  margin-bottom: 0 !important;
  line-height: 18px !important;
  font-size: 13px !important;
}

.customer-head .name h4 {
  margin: 0;
  font-weight: 400;
  padding: 10px 0 0 0;
  line-height: 18px;
}

.customer-head {
  padding: 10px 15px;
}
.customer-head > div >div{
    padding: 0 !important;
}
/* .customer-head .name {
  display: inline-block;
  width: 70%;
}

.customer-head .info {
  display: inline-block;
  width: 30%;
  vertical-align: top;
}*/

.customer-head .name .money-info {
  margin: 0;
}


.customer-head .info .price {
  padding-top: 10px;
}

.customer-head .name .money-info .status,
.customer-head .name .money-info .price {
  color: #178d1c;
  font-size: 14px;
  line-height: 18px;
  margin-top: 3px;
  padding-right: 5px;
}

.customer-head .name .money-info .status {
  font-size: 14px;
}
.customer-head .close{
    display: inline-block;
    width: 100%;
    text-align: right;
    font-size: 32px;
    line-height: 25px;
    cursor: pointer;
}
.customer-head .info .price{
    padding-right: 5px;
}
.customer-head .info .status {
  color: #178d1c;
  margin: 0;
    display: inline-block;
    width: 100%;
}

.customer-tabs > div > div:last-child > div > div > div.plan-head {
  border-bottom: 1px solid #ababab;
  padding: 5px 0px 5px 15px !important;
}

.customer-tabs > div > div:last-child > div > div > div.plan-head,
.customer-tabs > div > div:last-child > div > div > div.plan-body {
  margin-right: 10px;
}

.customer-plan .plan-head .info-part {
  display: inline-block;
  width: 100%;
}

.customer-plan .plan-head .button-part {
  display: inline-block;
  width: 100%;
  text-align: right;
  vertical-align: top;
  margin: 10px 0;
}
.customer-plan .plan-head .button-part div {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.28), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  background-color: #354156;
  color: #fff;
  border-radius: 3px;
  cursor: pointer;
  text-align: center;
    padding: 5px 10px;
    display: inline-block;
    margin-right: 2px;
    margin-bottom: 3px;
}

.customer-plan .plan-body {
  height: calc(100vh - 365px);
}

.customer-plan .plan-body .day {
  border-bottom: 1px solid #cecece;
  padding: 10px 15px;
}

.customer-plan .plan-body .day .status,
.customer-plan .plan-body .day .day-dishes,
.customer-plan .plan-body .day .actions {
  vertical-align: top;
  margin: 0;
}

.customer-plan .plan-body .day .status {
  display: inline-block;
  width: 15%;
}

.customer-plan .plan-body .day .status span {
  display: inline-block;
  padding: 15px 0;
  color: #999;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  font-weight: bold;
}

.customer-plan .plan-body .day.day-active .status span:first-child {
  color: #6d9c6f;
  font-size: 18px;
}

.customer-plan .plan-body .day.tomorrow .status span:first-child {
  font-weight: bold;
  font-size: 14px;
  color: #307c5c;
}

.customer-plan .plan-body .day.day-active .status span:last-child {
  color: #6d9c6f;
  font-size: 18px;
}

.customer-plan .plan-body .day.tomorrow .status span:last-child {
  font-weight: inherit;
  font-size: 14px;
  color: inherit;
}

.customer-plan .plan-body .day .status span:first-child {
  width: 28%;
  color: #999;
  vertical-align: middle;
}

.customer-plan .plan-body .day .status span:last-child {
  width: 70%;
  background: #fff;
  vertical-align: middle;
  width: calc(70% - 5px);
  padding-left: 5px;
}

.customer-plan .plan-body .day .day-dishes {
  display: inline-block;
  width: 50%;
  padding: 5px 0;
}

.customer-plan .plan-body .day .day-dishes p {
  margin: 0;
  font-size: 12px;
  line-height: 14px;
  color: #777;
}

.customer-plan .plan-body .day .actions {
  display: inline-block;
  text-align: right;
  width: 35%;
}

.customer-plan .plan-body .day .actions p {
  margin: 0;
}

.customer-plan .plan-body .day .actions.today p {
  color: #ff9800;
}

.actions.delivered p {
  color: #148f1980;
}

.plan-head .info-part .h-p1 {
  margin: 5px 0 0 0;
}

.plan-head .info-part .h-p2 {
  margin: 3px 0 5px 0;
}

.plan-head .info-part .h-p2 .type {
  color: #6d9c6f;
}
.plan-head .info-part p {
  margin: 0 0 5px 0px;
}
.plan-head .info-part .h-p2 .types {
  color: #666;
}

.plan-head .info-part .h-p1 .types,
.plan-head .info-part .h-p1 .plan {
  font-size: 16px;
  color: #9c27b0;
}

/*scroll-enr*/
/*feedback on profile*/
.feedback-holder {
  height: calc(100vh - 320px);
    padding: 10px;
  overflow: auto;
}

/*feedback on profile end*/

.feedback-holder .review .head-line .title,
.feedback-holder .review .head-line .stars,
.feedback-holder .review .head-line .date,
.feedback-holder .review .review-block,
.feedback-holder .review .photo {
  display: inline-block;
  vertical-align: top;
}

.feedback-holder .review {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  background: #fff;
  border-radius: 3px;
  margin: 3px !important;
}

.feedback-holder .review .photo {
  width: 150px;
  height: 120px;
  background: #ccc;
  vertical-align: top;
  background-size: cover;
  margin: 5px 0 5px 5px;
}

.feedback-holder .review .review-block {
  padding-left: 10px;
  padding-right: 10px;
  width: calc(100% - 180px);
}

.feedback-holder .review.general .review-block {
  width: calc(100% - 40px);
}

.feedback-holder .review.general {
  background: #f8daff;
  padding: 5px 0 0 0 !important;
}

.feedback-holder .review .head-line {
  width: 100%;
}

.feedback-holder .review .review-block .head-line p {
  margin: 5px 0;
}

.feedback-holder .review .review-block .head-line .stars {
  margin: 0;
}

.feedback-holder .review .review-block .head-line .title {
  font-size: 16px;
  font-weight: 400;
  width: 60%;
  line-height: 1.1;
}

.feedback-holder .review .review-block .head-line .date {
  width: 20%;
  font-size: 14px;
  color: #3C4858;
  text-align: right;
}

.feedback-holder .review.general .review-block .head-line .title {
  font-weight: 700;
}

.feedback-holder .review .review-block .head-line .stars {
  width: 20%;
  text-align: right;
}

.feedback-holder .review .review-block .review-text {
  font-size: 14px;
  line-height: 15px;
  padding-bottom: 10px;
  word-break: break-word;
  color: #777;
}

.feedback-holder .review.general .review-block .review-text {
    padding-bottom: 20px;
    width: 85%;
    text-align: justify;
    line-height: 20px;
}

.customer-tabs .notes {
  padding: 10px;
  height: calc(100vh - 385px);
}

.customer-tabs .notes .innre {
  width: 100%;
  height: 35vh;
  margin-bottom: 50px;
}

.h3-notes {
  font-size: 16px !important;
  text-transform: uppercase;
  margin-left: 10px !important;
  margin-top: 10px !important;
  color: #777 !important;
  font-weight: bold;
  margin-bottom: 10px !important;
}

.left-notes {
  float: left;
  width: 50%;
  padding: 10px;
}
.right-notes {
  float: right;
  width: 50%;
  padding: 10px;
}

.customer-tabs .notes .innre > div {
  margin-left: 3px;
  margin-right: 3px;
  box-shadow: 0 1px 4px 0 rgba(149, 45, 175, 0.16);
}

.customer-tabs .notes .innre > div > div > div {
  padding: 10px !important;
}

.customer-tabs .notes .innre > div > div > div p {
  margin: 5px 0;
}

.customer-tabs .notes-form {
    width: 100%;
    padding: 5px 10px 0;
    background: #fff;
    border-top: 1px solid #dedede;
    position: relative;
    top: -30px;
}

.customer-tabs .notes-form > div > div {
  width: 100%;
}

.customer-tabs .notes-form .title {
  position: absolute;
  left: 25px;
  bottom: 15px;
  color: #bebebe;
}

/* .fix-on-filters button {
    position: absolute;
    top: 3px;
    right: 10px;
} */

.package-board .top-position.styled {
  top: 95px;
  padding: 5px 15px;
  height: 73px;
}

.package-board .packs-holder {
  padding-top: 76px;
  width: 60%;
}

.package-board.can-be .packs-holder {
  width: 50%;
}

.package-board .top-position .filters-block {
  width: 100%;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .14);
  border-radius: 3px;
  background: #fff;
  padding: 5px 15px;
  width: calc(100% - 30px);
    height: 63px;
}

.package-board .top-position .filters-block > div {
  display: inline-block;
  text-align: right;
  width: 70%;
}

.package-board .top-position .filters-block > div button {
  text-transform: uppercase;
  padding: 7px 10px;
}

.package-board .top-position .filters-block > div:first-child {
  width: 30%;
  text-align: left;
}

.package-board .statistics {
  height: calc(100vh - 250px);
  position: fixed;
  top: 177px;
  right: 15px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .14);
  background: #fff;
  border-radius: 3px;
  width: calc(40% - 50px);
}

.package-board.can-be .statistics {
  width: calc(50% - 50px);
}

.package-board .packs-holder .package {
  padding: 0 15px;
}

.package-board.can-be .statistics .package .topack,
.package-board.can-be .statistics .package .list,
.package-board .packs-holder .package .status,
.package-board .packs-holder .package .package-desc,
.package-board .packs-holder .package .package-desc .date,
.package-board .packs-holder .package .package-desc .time,
.package-board .packs-holder .package .package-desc .id,
.package-board .packs-holder .package .package-desc .name {
  display: inline-block;
  margin-bottom: 0;
  vertical-align: top;
}

.package-board .packs-holder .package .package-desc {
  width: 80%;
}

.package-board .packs-holder .package .status {
  width: calc(10% - 20px);
  text-align: center;
  padding-right: 15px;
  position: relative;
}

.package-board.can-be .packs-holder .package .status {
  text-align: right;
  width: calc(20% - 5px);
  padding-right: 5px;
}

.package-board .packs-holder .package .package-desc .date,
.package-board .packs-holder .package .package-desc .time {
  width: 15%;
  font-weight: 700;
  color: #6d9c6f;
  font-size: 14px;
}

.package-board .packs-holder .package .package-desc .time {
  color: #ff9800;
}

.package-board .packs-holder .package .package-desc .id {
  width: 10%;
  font-size: 14px;
}

.package-board .packs-holder .package .package-desc .name {
  width: 60%;
  font-weight: bold;
}

.package-board .packs-holder .package .status span {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #cecece;
  display: inline-block;
  margin: 30px 5px;
  vertical-align: top;
}

.package-board.can-be .packs-holder .package .status span {
  margin: 10px 5px;
}

.package-board .packs-holder .package .status span.my {

}

.package-board .packs-holder .package .status span.done {
  background: #6d9c6f;
}

.package-board .packs-holder .package .status span.process {
  background: #ff9800;
}

.package-board .statistics {
  padding: 15px;
}

.package-board .statistics > div {
  background: #e7e7e7;
  border: 2px solid #cecece;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.package-board .statistics .done {
  background: #d8e3d9;
  border: 2px solid #6d9c6f;
}

.package-board .statistics p {
  margin: 5px 0;
  display: inline-block;
  width: 50%;
  text-align: center;
}

.package-board .statistics p:first-child {
  font-weight: bold;
  text-transform: uppercase;
}

.package-board .statistics .done {
  color: #5c835d;
}

.package-board .statistics .current {
  background: #f8e1bf;
  border: 2px solid #ff9800;
}

.package-board .statistics .current p:first-child {
  color: #975c04;
}

.package-board .packs-holder .package .status .my {
  background: #5c835d;
  position: relative;
}

.package-board .packs-holder .package .status .my:after {
  content: 'my';
  color: #f1f1f1;
  font-size: 12px;
  display: inline-block;
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
  padding-top: 2px;
}

.package-board .packs-holder .package .status .car {
  background: none;
  padding-right: 5px;
  line-height: 30px;
  font-size: 13px;
  width: auto;
}

.package-board .packs-holder .package .status .priority {
  background: none;
  text-align: center;
}

.package-board .packs-holder .package .status .priority.up:after {
  content: '➧';
  display: inline-block;
  font-size: 32px;
  transform: rotate(-90deg);
  color: #ff6100;
  font-weight: bold;
  line-height: 30px;

}

.package-board.can-be .statistics .head {
  background: #fafafa;
  border: 1px solid #cecece;
  padding: 5px 15px;
  margin-bottom: 5px;
}

.package-board.can-be .statistics .head .tilte,
.package-board.can-be .statistics .head .button-group {
  display: inline-block;
  width: 40%;
  vertical-align: top;
  text-align: right;
  text-transform: none;
}

.package-board.can-be .statistics .head .tilte {
  width: 60%;
  text-align: left;
}

.package-board.can-be .statistics .head .tilte span {
  padding-right: 10px;
  font-weight: 400;
}

.package-board.can-be .statistics .head .tilte .title {
  color: #ff6100;
}

.package-board.can-be .statistics .head .tilte span.driver {
  display: inline-block;
  width: 100%;
}

.package-board.can-be .statistics .head .tilte span.driver {
  color: #709f71;
  font-size: 13px;
}

.package-board.can-be .statistics .head .delivery-for {
  font-weight: 700 !important;
}

.package-board.can-be .statistics .packList,
.package-board.can-be .statistics .package {
  padding: 0;
  border: 0;
  background: none;
  padding-top: 5px;
}

.package-board.can-be .statistics .packList {
  height: calc(100vh - 335px);
  padding-right: 10px;
  padding-top: 0;
}

.package-board.can-be .statistics .package .topack {
  width: 20%;
  text-align: center;
}

.package-board.can-be .statistics .package .list {
  width: calc(80% - 10px);
  padding: 0 0 0 10px;
}

.package-board.can-be .statistics .package .topack .photo {
  width: 100%;
  height: 100px;
  background-size: cover;
  background-position: center;
}

.package-board.can-be .statistics .package .topack p {
  font-size: 13px;
  width: 100%;
  font-weight: bold;
  line-height: 1;
}

.package-board.can-be .statistics .package .list .head {
  font-size: 13px;
  font-weight: 400 !important;
  text-transform: uppercase;
  background: #bac1cc;
  border: none;
  border-radius: 3px;
  color: #354156;
  margin-bottom: 0;
}

.package-board.can-be .statistics .package .list .body {
  display: table;
  width: 100%;
  padding-bottom: 5px;
}

.package-board.can-be .statistics .package .list .body .imems-row {
  display: table-row;
  width: 100%;
}

.package-board.can-be .statistics .package .list .body .imems-row:nth-child(2n) {
  background: #f7f7f7;
}

.package-board.can-be .statistics .package .list .body p {
  width: 50%;
  border: none;
  font-weight: 400;
  text-transform: none;
  font-size: 14px;
  line-height: 1;
  margin: 0;
  border-radius: 0;
  text-align: left;
  padding: 5px 10px;
  vertical-align: middle;
  display: table-cell;
  box-shadow: none;
  border-bottom: 1px solid #eeeeee;
  position: relative;
}

.package-board.can-be .statistics .package .list .body p span {
  font-size: 14px;
}

.package-board.can-be .statistics .package .list .body p .checkHolder {
  position: absolute;
  right: 5px;
  top: -5px;
}

.package-board.can-be .statistics .package .list .body p.item {
  border-right: 1px solid #eeeeee;
  border-left: 1px solid #eeeeee;
}

.package-board.can-be .statistics .package .list .body p.item-group {
  border-left: 1px solid #eeeeee;
}

.package-board.can-be .statistics .package .list .body p .checkHolder > span {
  width: 30px;
  height: 30px;
}

.package-board.can-be .statistics .package .list .body p {
  padding-right: 15px;
}

.package-board.can-be .statistics .head .button-group button {
  width: calc(50% - 5px);
}

.package-board.can-be .statistics .head p {
  margin: 0;
}

.package-board.can-be .statistics .package.general .list {
  width: 100%;
  padding: 0;
}

.package-board.can-be .statistics .package.general .list .head {
  background: linear-gradient(60deg, #ca8ad5, #a346bb);
  box-shadow: 0 2px 2px -2px rgba(156, 39, 176, 0.28);
  color: #fff;
}

.package-board.can-be .statistics .general .list .body p,
.package-board.can-be .statistics .general .list .head p {
  width: 70%;
}

.package-board.can-be .statistics .general .list .body p:first-child,
.package-board.can-be .statistics .general .list .head p:first-child {
  width: 30%;
  font-weight: bold;
}

.package-board.can-be .statistics .package .list .body p:first-child {
  font-weight: bold;
}

.package-board .packs-holder.can .package {
  cursor: pointer;
}

.package-board .packs-holder.can .package:hover,
.package-board .packs-holder.can .package.active {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  background: rgb(251, 251, 251);
}

.package-board .packs-holder.can .package:hover .package-desc .name,
.package-board .packs-holder.can .package.active .package-desc .name {
  color: #ba6fcb;
}

.package-board .top-position.styled.all {
  top: 95px;
  margin: 5px 15px;
  width: calc(100% - 30px);
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  background: #fff;
  border-radius: 3px;
  padding: 9px 15px 9px 15px;
  height: 73px
}

.package-board .top-position.styled.all > .inputHolder {
  max-width: 12%;
  margin-right: 15px;
}
.filters.styled {
    background: #fff;
    padding: 5px 15px;
}
.filters.styled .whiteRow{
    display: flex;
    flex: 1 1;
    width: 100%;
    flex-direction: row;
}
.filters.styled .whiteRow .filter {
    margin-right: 10px;
    min-width: 11%;
}
.package-board .top-position.styled.all .fix-on-filters{
    width: 100%;
}
.package-board .top-position.styled.all .fix-on-filters > div > button,
.package-board .top-position.styled.all .buttonspart button {
  max-width: 12%;
  margin: 0 10px 0 0;
  position: absolute;
  right: 15px;
  top: 13px;
}

.d-inline-block {
  display: inline-block;
}

.pull-right {
  float: right;
}

.text-center {
  text-align: center;
}

.meal-check-holder {
  position: absolute;
  display: block;
  top: 5px;
  right: 5px;
  background: #ffffffb0;
  border-radius: 5px;
}

.meal-check-holder > span {
  width: 36px;
  height: 36px;
}

.link-butt {
  cursor: pointer;
  user-select: none;
}

.meal-check-holder span span .unChecked {
  color: #999;
  border: none;
  font-size: 32px;
  border-radius: 3px;
}

.meal-check-holder span span .checked {
  color: #8d29a5;
  border: none;
  font-size: 40px;
  border-radius: 3px;
}

.tooltip-yn .d-inline-block {
  width: calc(50% - 10px);
  padding: 0 5px;
}

.tooltip-yn .d-inline-block button {
  width: 100%;
}

.day-editing {
  font-size: 16px;
  text-transform: uppercase;
  background: #6d9c6f;
  line-height: 24px;
  padding: 3px 0;
  color: #fff;
  border-radius: 3px;
}

.day-editing span {
  color: #000000;
  font-weight: 400;
}

.logistic .package-board .top-position.styled.all {
  padding: 10px;
  width: calc(100% - 30px);
}

.logistic .package-board .top-position.styled.all button {
  position: relative;
  max-width: none;
  right: 0;
  top: 0;
  padding: 5px 10px;
  text-align: center;
  margin-right: 5px;
}

.log-holder > div .body .car .visits button > span,
.logistic .package-board .top-position.styled.all button > span {
  display: inline-block;
}

.log-holder > div .body .car .visits button p,
.logistic .package-board .top-position.styled.all button p {
  width: 100%;
  margin: 0;
  font-size: 14px;
}

.log-holder > div .body .car .visits button .deliv,
.log-holder > div .body .car .visits button .bagv,
.logistic .package-board .top-position.styled.all button .deliv,
.logistic .package-board .top-position.styled.all button .bagv {
  display: inline-block;
  padding: 3px;
  text-transform: none;
}

.log-holder {
  margin-top: 78px;
  display: flex;
  flex-direction: row;
  height: calc(100vh - 195px);
}

.rounded-shadowed {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .14);
  background: #fff;
  border-radius: 3px;
}

.log-holder .cars-holder {
  width: 25%;
  margin-right: 3px;
  position: relative;
}

.log-holder .map-holder {
  width: 40%;
  overflow: hidden;
}

.log-holder .visits-holder {
  width: 35%;
  margin-right: 3px;
}

.log-holder > div {
  height: 100%;
}

.log-holder > div .body {
  height: calc(100% - 35px);
}

.log-holder > div .head {
  text-align: left;
  border-bottom: 1px solid #eee;
  padding: 5px 10px;
  background: #fff;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  position: relative;
}

.log-holder > div .body .car {
  border-bottom: 1px solid #eeeeee;
  padding: 10px 10px 10px 8px;
  background: #fff;
  cursor: pointer;
}

.log-holder > div .body .car .total > div {
  display: inline-block;
  width: 50%;
  vertical-align: top;
}

.log-holder > div .body .car .total > div.right {
  text-align: right;
}

.log-holder > div .body .car .total > div p {
  margin: 0;
}

.log-holder > div .body .car .total > p {
  margin: 0 0 5px 0;
  font-size: 14px;
  font-weight: 700;
  color: #354156;
}

.log-holder > div .body .car .total > div span {
  font-size: 14px;
  padding-right: 5px;
  font-weight: 400;
}

.log-holder > div .head {
  font-weight: 400;
  font-size: 14px;
}

.log-holder > div .body .car .can-number {
  font-size: 13px;
  font-weight: 400;
  color: #6d9c6f;
}

.log-holder > div .body .car .visits > p {
  padding: 5px 10px 0;
  font-size: 14px;
  border-radius: 3px;
  margin: 3px 3px 0 0;
  display: inline-block;
  border: 1px solid #aabfab;
  color: #717171;
}

.log-holder > div .body .car .visits > p .window {
  width: inherit;
  display: block;
  line-height: 14px;
  margin: 0;
  text-align: center;
  text-transform: uppercase;
}

.log-holder > div .body .car .visits > p .no-visits {
  font-size: 12px;
  line-height: 12px;
}

.log-holder > div .body .car.active .visits > p.success {
  background-color: #6d9c6f;
  color: #fff;
}

.log-holder > div .body .car {
  border-left: 2px solid #fff;
}

.log-holder > div .body .car.active,
.log-holder > div .body .car:hover {
  border-left: 2px solid #6d9c6f;
}

.log-holder > div .body .car .visits > p .deliv,
.log-holder > div .body .car .visits > p .bagv {
  padding: 0 10px 0 22px;
  width: auto !important;
  position: relative;
  outline: none;
  justify-content: center;
}

.pin.icon {
  color: inherit;
  position: absolute;
  width: 10px;
  left: 2px;
  top: 2px;
  height: 10px;
  border: solid 1px currentColor;
  border-radius: 7px 7px 7px 0;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  display: block;
}

.pin.icon:before {
  content: '';
  position: absolute;
  left: 2px;
  top: 2px;
  width: 4px;
  height: 4px;
  border: solid 1px currentColor;
  border-radius: 3px;
}

.lock.icon {
  color: inherit;
  position: absolute;
  left: 4px;
  top: 8px;
  width: 10px;
  height: 6px;
  border-radius: 1px;
  border: solid 1px currentColor;
  display: block;
}

.lock.icon:before {
  content: '';
  position: absolute;
  left: 2px;
  top: -6px;
  width: 4px;
  height: 6px;
  border-radius: 4px 4px 0 0;
  border-top: solid 1px currentColor;
  border-left: solid 1px currentColor;
  border-right: solid 1px currentColor;
}

.normal {
  font-weight: 400;
}

.visit-list .visit {
  display: flex;

}

.visit-list .visit .part {
  width: 60%;
}

.visit-list .visit .part:last-child {
  width: 40%;
  text-align: right;
}

.visit-list .visit .part p {
  margin: 0;
}

.text-right {
  text-align: right;
}

.head .buttons {
  width: 50%;
  position: absolute;
  right: 0;
  top: 3px;
  margin: 0;
  text-align: right;
  padding-right: 10px;
}

.tooltip-edit button,
.head .buttons button {
  margin: 0;
  padding: 5px;
  margin-left: 5px;
  min-height: 23px;
}

.tooltip-edit button {
  min-height: 30px;
}

.head .rc-tooltip {
  min-height: 30px;
  padding: 8px;
}

.visit-list .visit {
  border-bottom: 1px solid #eee;
  padding: 10px;
  background: #fff;
}

.visit-list .visit .name {
  font-weight: 700;
  font-size: 14px;
}

.visit-list .visit .address span {
  font-size: 14px;
  line-height: 14px;
}

.visit-list .visit .address span:first-child {
  font-weight: bold;
}

.visit-list .visit .phone {
  font-size: 14px;
  font-weight: 400;
}

.visit-list .visit .delivery span {
  height: auto;
  width: auto;
  padding-left: 10px;
  font-size: 13px;
  font-weight: bold;
}

.visit-list .visit .delivery {
  display: inline-flex;
  align-items: center;
}

.visit-list .visit .delivery span.delivered {
  color: #1f881f;
}
.visit-list .visit .delivery span.delivered:after{
    content: ' done';
}

.visit-list .visit .delivery span.not {
  color: #888;
}

.visit-list .visit.not-editable .delivery span .checked,
.visit-list .visit.not-editable .delivery span .unChecked {
  border: none;
}

.visit-list .visit.editable .delivery span svg {
  border: 1px solid #cecece;
  border-radius: 3px;
}

.logistic .package-board .top-position.styled.all .buttonspart {
  width: 70%;
  display: inline-flex;
}

.checked-checked {
  color: #9c27b0;
  border: none;
}

.logistic .package-board .top-position.styled.all .totalpart {
  width: 30%;
  display: inline-flex;
  flex-direction: column;
}

.chhose {
  margin: 10px 10px;
  font-size: 14px;
  color: #1f881f;
  text-transform: uppercase;
  display: inherit;
}

.info-icon {
  background: #fff;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  display: inline-block;
  text-align: center;
  font-size: 10px;
  line-height: 18px;
  margin-left: 5px;
  cursor: pointer;
  border: 1px solid #ff9800;
}

.info-icon:hover {
  background: #9c27b0;
  border: 1px solid #9c27b0;
  color: #fff;
}

.my-tt p {
  font-size: 14px;
  font-weight: normal;
  margin: 0;
  padding: 10px;
}

.my-tt p span {
  font-weight: bold;
  padding-right: 5px;
}

.my-tt p:last-child {
  margin-bottom: 0;
}

.my-tt p:nth-child(2n+1) {
  background: #fbfdfbb5;
}

.my-tt p.instructions {
  background: #c9e4cdb5;
}

.cars-holder .head span.planned {
  color: #1f881f;
  padding-right: 10px;
}

.cars-holder .head span.not-planned {
  color: #ff9800;
}

.rc-tooltip {
  background-color: #fff !important;
  opacity: 1 !important;
}

.rc-tooltip-inner {
  border: 1px solid #d7d7d7 !important;
}

.visit.not-planned {
  opacity: .6;
}

.visit.not-planned b {
  font-size: 12px;
}

.visit.editable.not-planned {
  opacity: 1;
}

.order-arrows {
  display: inline-block;
  margin-left: 10px;
  vertical-align: top;
}

.order-arrows p {
  cursor: pointer;
  line-height: 16px;
  display: inline-block;
  padding: 0 3px;
}

.order-arrows p:first-child {
  font-size: 28px;
  transform: rotate(-90deg);
  color: #1f881f;
  font-weight: 700;
  line-height: 30px;
}

.order-arrows p:last-child {
  font-size: 28px;
  transform: rotate(90deg);
  color: #ff6100;
  font-weight: 700;
  line-height: 30px;
}

.head .inputHolder {
  width: 40%;
}

.head .inputHolder > div > div {
  margin-top: 0;
}

.head .inputHolder > div > div > div {
  padding: 0 0 3px 0;
  min-height: auto;
  font-size: 14px;
}

.logistic-calendar {
  position: absolute;
  z-index: 2000;
  padding: 25px;
  box-shadow: 0 0 5px #b5b5b5;
  background: #fff;
  border-radius: 5px;
}

.logistic-calendar > .modal-close-button {
  right: 0;
  top: 0;
  padding: 3px;
}

.customer-tabs .customer-info .map-edit-holder {
  position: absolute;
  margin: 10px;
  padding: 10px !important;
  background: #fff;
  z-index: 10;
  border-radius: 5px;
  box-shadow: 0 0 5px #7a7a7a;
}

.customer-tabs .customer-info .map-edit-holder .separator {
  border-left: 1px solid #9f9b9b;
  height: 100%;
  width: 0;
  margin: 0 8px;
  display: inline;
}

.map-edit-holder button {
  margin: 0 5px;
}

.not-align-right tr th:last-child {
  text-align: left !important;
}

.roundedHeader tr.totals-row th {
  background-color: #fff;
  color: #354156;
}

.roundedHeader tr.totals-row th.performed {
  background-color: #d5e8d4;
}

.financetbl th, .financetbl td {
    font-size: 11px !important;
    padding: 6px !important;
}

th.performed div,
th.pending div {
  margin: 0 20px;
}

.top-menu {
  border: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  width: 100%;
  height: 50px;
}

.top-menu .background {
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 100%;
  display: block;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: center center;
  background-image: radial-gradient(circle, #36465f, #313c51, #2b3344, #252a37, #1e212b);
}

.top-menu .background :after {
  position: absolute;
  z-index: 3;
  height: 100%;
  width: 100%;
  content: '';
  display: block;
  background-image: radial-gradient(circle, #36465f, #313c51, #2b3344, #252a37, #1e212b);
  opacity: .8;
}

.menu-handler {
  display: flex;
  justify-content: space-between;
  height: 100%;
  width: 100%;
}

.butt-handler {
  height: 100%;
  background: #fff;
}

.butt-wrapper {
  display: flex;
  justify-content: flex-start;
  padding: 0 15px;
  align-items: center;
  height: 100%;
}

.menu-button {
  /*position: absolute;*/
  display: flex;
  align-items: center;
  cursor: pointer;
  z-index: 9;
  padding: 0 10px;
  color: #fff;
}

.home-butt {
  background-color: #333d53;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
}

.links-list {
  height: 100%;
  flex-grow: 1;
  z-index: 9;
}

.links-list > .list-container {
  height: 100%;
  display: flex;
  justify-content: space-between;
}

.links-list .menu-link {
  display: inline-flex;
  align-items: center;
  flex-grow: 1;
  text-decoration: none;
  width: auto;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  transition: all 300ms linear;
  height: 100%;
  color: #fff;
  padding: 0 10px;
}
header{
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.35) !important;
}
header ~ div {
    margin-top: 50px !important;
    padding-top: 0 !important;
}
header ~ div > div {
    padding: 0 !important;
}
.link-body {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent !important;
}

.link-body.active-link .link-icon svg path,
.link-body:hover .link-icon svg path{
    fill: #cf56e5;
}
.link-body.active-link .link-text,
.link-body:hover .link-text{
    border-bottom: 1px solid #cf56e5;
}
.link-body .link-icon{
    margin-right: 10px;
}
.link-body .link-icon,
.link-body .link-text {
  font-size: small;
  line-height: 25px;
  height: 25px;
    border-bottom: 1px solid transparent;
}

.log-out-butt {
  height: 100%;
  right: 0;
  background: linear-gradient(90deg, #1f232d, #08075a);
}

.log-out-butt:hover {
  background: linear-gradient(90deg, #1f232d, #c8c8ec);
}

.react-calendar button {
  margin: 0 !important;
  border: 0;
  outline: none;
}

.dishes-list .component-row:nth-child(even) {
  background: #f5f5f5;
}

.component-row {
  padding: 10px 20px;
}

.cooked-menu .component-row:nth-child(odd) {
  background: #e4fafb;
}

.cooked-menu .component-row:nth-child(even) {
  background: #fdfcd0;
}

.dish-info .cooked {
  float: right;
}

.back-side-butt {
  position: absolute;
  left: 0;
  top: 70px;
  width: 12px;
  height: 50px;
  background-image: radial-gradient(circle, #36465f, #313c51, #2b3344, #252a37, #1e212b);
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  transition: width .5s;
  z-index: 1000;
  cursor: pointer;
}

.icon-back {
  height: 100%;
  color: #fff;
  display: flex;
  align-items: center;
  transition: margin-left .5s;
  margin-left: 0;
}

.icon-back svg {
  font-size: 3em;
  margin-left: -5px;
}

.back-side-butt:hover {
  width: 40px;
}

.back-side-butt:not(:hover) > .icon-back {
  margin-left: -35px;
}

.cal-holder {
  padding-right: 5px;
  position: absolute;
  display: block;
  width: 22%;
  background: #fff;
  z-index: 999;
}

.rtdDay.disabled {
  filter: grayscale(100%);
  opacity: 0.7;
}

.tooltip-yn .button-text {
  flex-grow: 1;
}

.day .remain-buttons {
  margin: 0 0 0 auto !important;
  float: right;
}
.simple-button{
  text-align: center;
  border-radius: 3px;
  padding: 2px 8px;
  cursor: pointer;
  min-width: 50px;
}
.remain-buttons .button {
  margin: 5px 0 0 5px!important;
  text-align: center;
  border-radius: 3px;
  padding: 2px 8px;
  cursor: pointer;
}
.bg-primary{
  background-color: #9c27b0;
  color: #fff;
}
.bg-warning{
  background-color: #ff9800;
  color: #fff;
}
.bg-danger{
  background-color: #f44336;
  color: #fff;
}
.bg-success{
  background-color: #6d9c6f;
  color: #fff;
}
.bg-info{
  background-color: #00acc1;
  color: #fff;
}
.bg-rose{
  background-color: #e91e63;
  color: #fff;
}
.bg-gray{
  background-color: #999999;
  color: #fff;
}
.primary-shadow:hover{
  box-shadow: 0 12px 20px -10px rgba(156, 39, 176, 0.28), 0 4px 20px 0 rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(156, 39, 176, 0.2);
}
.warning-shadow:hover{
  box-shadow: 0 12px 20px -10px rgba(255, 152, 0, 0.28), 0 4px 20px 0 rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(255, 152, 0, 0.2);
}
.danger-shadow:hover{
  box-shadow: 0 12px 20px -10px rgba(244, 67, 54, 0.28), 0 4px 20px 0 rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(244, 67, 54, 0.2);
}
.success-shadow:hover{
  box-shadow: 0 12px 20px -10px rgba(76, 175, 80, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(76, 175, 80, 0.2);
}
.info-shadow:hover{
  box-shadow: 0 12px 20px -10px rgba(0, 188, 212, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(0, 188, 212, 0.2);
}
.dark-blue-shadow:hover{
  box-shadow: 0 12px 20px -10px rgba(0, 26, 212, 0.28), 0 4px 20px 0 rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(0, 144, 212, 0.2);
}


/* media */
@media (max-width: 1024px) {
    .log-holder>div .body .car .visits>p{
        padding: 5px 5px 0;
    }
    .log-holder .head .buttons{
        padding-right: 5px;
    }
    .log-holder .head .buttons button{
        min-width: unset;
    }
    .package-board.can-be .statistics .head{
        padding: 5px;
    }
    .package-board.can-be .statistics .head .client{
       display: inline-block;
        width: 100%;
    }
    .package-board.can-be .statistics .head .button-group button{
        width: max-content;
        padding: 3px;
        min-width: unset;
        margin-bottom: 1px;
        margin-top: 2px;

    }
    .package-board.can-be .statistics .head .button-group button span{
        text-transform: lowercase;
        font-size: 13px;
    }

    .package-board .fix-on-filters {
        width: calc(100vw - 190px) !important;
        overflow: auto;
    }
    .customer-wrap.customers-page .fix-on-filters {
        width: calc(100vw - 170px) !important;
        overflow: auto;
    }
    .fix-on-filters>div {
        min-width: 170px !important;
        width: 170px !important;
        max-width: 170px !important;
    }
    .fix-on-filters>div.buttons-on-filters {
        min-width: 125px !important;
        width: 125px !important;
        max-width: 125px !important;
    }
    .finances .fix-on-filters .buttons-on-filters{
        position: absolute;
        right: 10px;
    }
    .finances .buttons-on-filters button, .finances .fix-on-filters .buttons-on-filters button {
        padding: 10px 5px;
        min-width: 50px;
    }
    .customer-tabs>div>div>div>div>div>div button {
        padding: 0 5px;
    }
    .customer-item {
        top: 181px;
        right: 10px;
        flex-basis: calc(50% - 10px);
        width: calc(50% - 10px);
    }
}
.package-board.can-be .statistics{
    overflow: hidden;
}
@media (max-width: 900px) {
    .package-board .top-position .filters-block{
        display: flex;
        justify-content: space-between;
        overflow-y: auto;
        box-sizing: content-box;
        height: 100%;
    }
    .package-board .top-position .filters-block .filters-package {
        width: unset !important;
        display: inline-flex;
        margin-right: 3px;
    }
    .package-board.can-be .statistics .head .button-group{
        text-align: right;
        position: absolute;
        top: 20px;
        right: 20px;
    }
    .dishes .day .dish .image{
        width: 100%;
        height: 106px;
        display: block;
        margin-right: 0;
        float: unset;
    }
    .customer-wrap.customers-page .customers-inner a{
        display: block;
        width: calc(100% - 20px);
        margin: 0 10px;
    }

    .customer-item {
        top: 50px;
        right: 0;
        flex-basis: calc(100% - 20px);
        width: calc(100% - 20px);
        padding: 0 10px;
        background: #fff;
        height: calc(100vh - 50px);
    }
    .customer-item > div div,
    .customer-item > div {
        box-shadow: none;
    }
    .customer-plan{
        flex-direction: column;
    }
    .customer-plan,
    .customer-info {
        height: calc(100vh - 160px);
        width: 100%;
    }
    .plan-head{
        height: auto;
    }
    .plan-head, .plan-body{
        width: calc(100% - 20px);
    }
    .tabs-outher{
        display: flex !important;
    }
    .tabs-outher button{
        text-align: center;
        padding: 0 10px;
        box-sizing: content-box;
    }
    .tabs-outher button span{
        padding: 0;
        text-align: center;
        text-transform: lowercase;
        margin: 0;
    }
    .progress {
        height: calc(100vh - 180px);
        padding: 15px;
    }
    .customer-item-empty{
        display: none;
    }
    .customer-tabs .notes,
    .feedback-holder,
    .customer-plan .plan-body{
        height: calc(100vh - 228px);
    }
    .customer-tabs .notes > div > div {
        width: 100%;
    }
    .customer-tabs .notes .innre {
        width: 100%;
        height: 35vh !important;
        margin-bottom: 50px;
        overflow-y: auto;
        overflow-x: hidden;
    }
    .tabsCustom div button{
        width: 75px;
        height: 30px;
        padding: 2px 2px;
    }
    .tabsCustom div button span {
        text-transform: lowercase;
        display: inline;
        white-space: unset;
        line-height: 1;
    }
    div[class^="MuiGrid"],
    div[class^="MuiPaper"],
    div[class^="MuiGrid-typeItem"] {
        width: 100%;
        box-sizing: border-box;
    }
    .h-80 {
        height: 100vh !important;
    }
    .programsHeading .head.sub > div,
    .tabsCustom > div {
        height: 100%;
        display: flex;
        justify-content: space-between;
        overflow-y: auto;
        box-sizing: content-box;
    }
    .programsHeading .head.sub > div button,
    .tabsCustom > div button {
        display: inline-flex;
        align-items: center;
        flex-grow: 1;
    }
    .programsHeading .head.sub > div button {
        padding: 0;
    }
    .programsBody  div[class^="MuiGrid-typeContainer"] {
        width: 65.33333%;
        flex-basis: 65.33333%;
    }
    .programsBody .holderWithBg .holder .left,
    .programsBody .holderWithBg .holder .right{
        display: none;
    }
    .programsBody .dishes, .programsBody .dishes-head{
        width: 32.33333%;
        flex-basis: 32.33333%;
    }
    .prices-table input {
        width: 30px!important;
    }
}
@media (max-width: 768px) {
    .package-board:not(.can-be) .statistics{
        display: none;
    }
    .package-board.can-be .statistics .head .tilte{
        width: 100%;
    }
    .package-board.can-be .statistics{
        width: calc(100%);
        z-index: 999;
        top: 42px;
        height: calc(100vh - 42px);
        overflow: auto;
        left: 0;
        box-sizing: border-box;
    }
    .package-board.can-be .statistics .packList{
        overflow: unset;
        height: auto;
        padding-right: 0;
    }
    .package-board .packs-holder{
        width: 100% !important;
    }
    .package-board .packs-holder .package .status span{
       line-height: 30px;
       margin: 0;
        margin-top: 10px;
    }
    .package-board .packs-holder .package .package-desc{
        width: 100%;
    }
    .package-board.can-be .packs-holder .package .status {
        text-align: right;
        width: 100%;
    }
    .package-board.can-be .packs-holder .package .status span{
        display: inline-block;
    }
    .package-board .packs-holder .package .package-desc .name{
        width: 100%;
    }
    .package-board .packs-holder .package .package-desc .date,
    .package-board .packs-holder .package .package-desc .time,
    .package-board .packs-holder .package .package-desc .id{
        display: inline-block;
        width: auto;
        padding-right: 5px;
    }
    .on-mobile {
        padding: 20px;
        width: calc(100vw - 60px);
    }
    .customer-item .customer-info .map-holder{
        height: calc(100vh - 230px)!important;
    }
    /*.customer-item .customer-info .map-holder div{
        height:100% !important;
    }*/
    .notes-form{
        margin-top: 10px;
    }
    .plan-edit-buttons > div{
        margin-bottom: 3px;
    }
    .customer-item .progress{
        padding: 0;
        margin-top: 20px;
    }
    .customer-item .progress button{
        right: 0 !important;
    }
    .customer-item .progress .progress-holder .progress-chart-navigation{
        text-align: left;
    }
    .customer-item .progress .progress-holder .progress-chart-navigation li{
        margin-left: 0;
        margin-right: 5px;
        padding: 0 4px;
    }
    .tabs-outher{
        width: calc(100vw - 35px);
        overflow: auto!important;
        white-space: nowrap;
    }
    .tabs-outher button {
        display: inline-block;
        width: auto !important;
    }
    .customer-head > div > div:last-of-type {
        position: absolute;
        width: max-content;
        right: 5px;
        top: 5px;
    }
    .cast-cast,
    .notes div,
    .progress,
    .customer-plan div{
        overflow: unset;
        height: auto !important;

    }
    .customer-plan .plan-body .day .status span:last-child{
        padding-left: 10px;
    }
    .customer-plan .plan-body.scrollable-h{
        width: 100%;
    }
    .customer-plan .plan-body .day > div{
        display: block !important;
        width: 100% !important;
    }
    .customer-plan .plan-body .day .group-title{
        margin: 0;
    }
    .customer-item{
        overflow: auto;
        overflow-x: hidden;
    }
    .customer-item > div {
        overflow: unset;
        height: auto !important;
    }
    .customer-item .customer-tabs .notes-form {
        padding: 0;
    }
    .customer-plan .plan-body .day > div span {
        display: inline !important;
        padding: 0 !important;
    }
    div[class^=MuiGrid-typeItem], div[class^=MuiGrid], div[class^=MuiPaper] {
        padding: 0 !important;
    }
    .modal-close-button svg{
        width: 30px;
        height: 30px;
    }
    .dish-wrapper .list-wrap,
    .dish-wrapper,
    .full-w,
    .afterTabsCustom .full-w {
        width: max-content;
    }
    .full-w .filters.styled .whiteRow .filter {
        margin-right: 10px;
        min-width: 120px;
    }
    .afterTabsCustom.full-w.kitchen-o .fix-on-filters .buttons-on-filters{
        position: relative;
    }
    .afterTabsCustom.full-w.kitchen-o .fix-on-filters{
        overflow: unset;
        width: max-content !important;
    }
    .afterTabsCustom .full-w .fix-on-filters > div {
        width: 100%!important;
    }
    .afterTabsCustom .full-w .fix-on-filters > div {
        min-width: 13%!important;
        width: 13%!important;
        max-width: 13%!important;
    }
    .afterTabsCustom .full-w .fix-on-filters .buttons-on-filters {
        position: relative;
    }
    .afterTabsCustom .full-w .fix-on-filters .buttons-on-filters button {
        padding: 12px 32px;
    }
    .customer-wrap .top-position,
    .package-board .top-position {

    }
}
@media (max-width: 600px) {
    .package-board.can-be .statistics .packList .package .topack{
        width: 100%;
    }
    .package-board.can-be .statistics .package .list {
        width: 100%;
        padding: 0;
        margin-top: 10px;
    }
}
@media (max-width: 480px) {

}
@media (max-width: 350px) {

}
.chart-holder,
.progress-holder{
    display: block !important;
    width: 100%;
    margin-top: 20px;
}

.modal-wrapper-custom {
    z-index: 1001;
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 100% !important;
    box-sizing: border-box;
    background: none;
    background-color: transparent !important;
}
.modal-wrapper-custom .modal-body{
    padding: 15px;
    max-height: calc(100vh - 70px);
    max-width: 100vw;
    overflow: auto;
    width: max-content;
    height: max-content;
    position: absolute;
    top: 40px;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    box-sizing: border-box;
}
.modal-wrapper-custom .modal-body .scroll-wrapper{
    max-width: 1024px;
}
.modal-wrapper-custom .modal-body .new-order{
    /*width: 900px;*/
}
.modal-wrapper-custom .modal-body .edit-modal-inner .rdtPicker{
    width: 300px;
    padding: 10px 0;
}

.edit-sides-modal {
    width: 640px;
}

.max-900 {
    max-width: 900px !important;
}
.max-1024 {
    max-width: 900px !important;
}
.max-v100 {
    max-width: 100% !important;
}
.h-80{
    height: 80vh;
}
.dish-wrapper {
    width: 100%;
}
.dish-wrapper .list-wrap{
    background: #fff;
}
.blog {
    padding-top: 20px;
}
.history-holder div {
    box-shadow: none !important;
}
.history-cancelled{
    background: #f9b5b56b;
}
.history-finished{
    border: 1px solid #eee;
}
.history-future{
    background: #cf56e53b;
}
.gistory .status{
    font-weight: bold;
    text-transform: uppercase;
}
.history-active{
    background: #56c1983b;
}
.actions-part {
  display: block;
  position: absolute;
  right: 10px;
  top: 15px;
}
.actions-part .buttons{
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
/* pack print */
.pack-print{
    width: 700px;
    max-width: 100%;
    padding: 0 10px;
}
.pack-print #printable-content .pack-list{

}
.pack-print #printable-content .pack-list .package .list{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.pack-print #printable-content .pack-list .package .list .body .imems-row{
    display: flex;
    flex-direction: row;
    width: 100%;
}
.pack-print #printable-content .pack-list .package .list .body .imems-row p,
.pack-print #printable-content .pack-list .package .list .head p{
    width: 50%;
}
/*.pack-print #printable-content .pack-list .package .list .head {
    display: none;
}*/
.pack-print #printable-content .pack-list .head.head-print {
    display: flex;
    flex-direction: row;
    width: 100%;
}
.pack-print #printable-content .pack-list .package .list .head {
    display: none;
}
.pack-print #printable-content .pack-list .head.head-print p{
    background: #ebebeb;
    padding-left: 5px;
    height: 2rem;
    line-height: 2rem;
    width: 50%;
  font-weight: bold;
}
.pack-print #printable-content .pack-list p.list-title{
    font-size: 18px;
}
.pack-print #printable-content .pack-list p.list-title span{
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
}
.pack-print #printable-content .pack-list .package:nth-child(2n) .list .body .imems-row{
    background: #ebebeb;
}
.pack-print #printable-content .pack-list .package .list .body .imems-row p.item-group{
    padding: 10px 5px;
    margin: 0;
    height: auto;
}
.pack-print #printable-content .pack-list .package .list .body .imems-row p.item{
    text-align: left;
    border: none;
    margin: 0;
    box-shadow: none;
    line-height: 1;
    padding: 10px 5px;
    height: auto;
}
.pack-print #printable-content .pack-list .package .list .body .imems-row p.item span{
    font-size: 16px;
}
.new-order .edit-modal-inner{
    overflow: hidden;
    padding-bottom: 20px;
}
.special-label{
  position: absolute;
  height: 100%;
  width: 25px;
  right: 0;
  top: 0;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
  text-align: center;
}
.special-label>span{
  height: 100%;
  font-size: 11px;
  writing-mode: vertical-lr;
  text-orientation: upright;
  text-transform: uppercase;
}

#mealplan-calendar-side {
  float: right;
  margin-top: -45px;
  margin-left: 1010px;
  width: 800px;
}

#mealplan-calendar-side button {
  position: relative;
  top: -5px;
}

.PlanDetailsHeader {
  text-align: center;
  color: white;
  font-size: 12px;
  font-weight: bold;
  margin-top: 15px !important;
  padding: 7px;
  background-color: #6d9c6f;
}

.left-align-td {
    text-align: left !important;
}

.days-month-line {
    font-weight: bold !important;
    font-size: 20px !important;
}

.row-border-top td {
    background-color: #d5e8d4;
}

.row-border-top td b {
    font-weight: bold;
}

.row-border-top-final td {
    font-weight: bold;
    background-color: #d3ffce;
}

ul.timeline {
  list-style-type: none;
  position: relative;
}
ul.timeline:before {
  content: ' ';
  background: #d4d9df;
  display: inline-block;
  position: absolute;
  left: 29px;
  width: 2px;
  height: 100%;
  z-index: 400;
}
ul.timeline > li {
  margin: 20px 0;
  padding-left: 50px;
}
.float-right {
  float: right !important;
  margin-right: 10px;
}
ul.timeline > li:before {
  content: ' ';
  background: #fff;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 3px solid #22c0e8;
  left: 22px;
  width: 10px;
  height: 10px;
  z-index: 400;
}

.alert {
  border: 1px solid transparent;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 15px;
}
.alert-unsaved {
  background-color: #90aa9e;
  border-left: 5px solid #d9180e;
  position: fixed;
  width: 97.6%;
  z-index: 9999;
  left: 0;
  color: #fff;
  text-align: center;
  border-right: 5px solid #dd1105;
}
.alert-unsaved strong{
  margin-left: 5px;
}
.alert-unsaved .close {
  border-color: #8a5700;
  color: #8a5700;
}
.alert-unsaved .close {
  position: absolute;
  width: 30px;
  height: 30px;
  opacity: 0.5;
  border-width: 1px;
  border-style: solid;
  border-radius: 50%;
  right: 15px;
  top: 25px;
  text-align: center;
  font-size: 1.6em;
  cursor: pointer;
}

.bagcollect-inner-holder {
  overflow-x: scroll;
  overflow-y: visible;
  padding: 0;
  width: 100%;
  max-width: 1600px;
}
.prof-red {
  color: red;
}
.prof-yellow {
  color: yellow;
}
.mealrow-meals-recipe{
  float: left;
  width: 100%;
}
.receipe-container{
   max-width: 1100px;
   margin: 0 auto;
}
.container-fluid{
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.col-lg-6 {
  -ms-flex: 0 0 47%;
  flex: 0 0 47%;
  max-width: 50%; 
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.d-flex {
  display: -ms-flexbox !important;
  display: flex !important;
}
.align-items-center {
  -ms-flex-align: center !important;
  align-items: center !important;
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}
.table-bordered {
  border: 1px solid #dee2e6;
}
.table td, .table th {
  padding: .75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.rec-img-lt img
  {
    width:100%;
  }
.rec-h-holder
{
  padding: 10px 0px;
  text-align: center;
  background-color: #6D9C6F;
  color:#fff;
}
.recp-table-header th
{
  background-color:#6D9C6F;
  color:#fff;
}
.rec-body
{
  padding-bottom:30px;
}
.rec-h-holder h2
{
  margin-bottom:0px;
  font-size: 2rem;
  margin: 0;
  padding: 0;
}
.rec-table-wrapper
{
  margin-top:30px;
}
.rec-table-wrapper .table-bordered td, .rec-table-wrapper .table-bordered th
{
  border:1px solid #DCEDDD;
}
.mealrow-meals-recipe .mealrow-item {
  height: auto;
  min-height: 280px;
}
.row .date-date{
  width: 99.5%;
}
.logo {
  color: #fff;
  position: relative;
  z-index: 9999;
  line-height: 50px;
  margin-left: 25px;
  background: #5b9c85 !important;
  padding: 0 10px;
  cursor: pointer;
  width: 150px;
}
.logo.link-body .link-icon {
  margin-right: 5px;
}
.logo.link-body .link-text {
  font-weight: bold;
}
.logo.link-body:hover .link-text{
  border-bottom: 1px solid #b7995e;
}
.logo.link-body .link-icon svg path{
  fill: #fff;
}
.customer-tabs .notes.deliveryschedule .innre.scrollable-h{
  height: calc(100vh - 370px);
}
.notes.deliveryschedule .cell-body{
  padding: 4px 40px 4px 10px;
}
.notes.deliveryschedule .delivery-contain.inputHolder {
  width: 133px;
}
.notes.deliveryschedule .delivery-contain .select-input,.delivery-contain.inputHolder > div > div > div {
  font-size: 12px;
}
.delivery-partner-select {
  display: inline-block;
  min-width: 200px;
  clear: both;
  margin: 0px 10px;
  background: #fff;
  padding: 0 10px 0 10px;
}
.timeline ol li {
  list-style: disclosure-closed;
}
.timeline li strong, .timeline li b {
  font-weight: bold;
}
.message h6 {
  font-size: 14px;
  margin: 0;
  padding: 0;
  font-weight: bold;
  text-transform: none;
}
.message li {
  font-size: 13px;
}
.whiteFoodRow td:first-child {
  text-align: left;
}
.whiteFoodRow td:last-child {
  text-align: center;
}
.whiteFoodRow td.align-right{
  text-align: right;
}
.roundedHeader.foodcostall tr th:last-child{
  text-align: center;
}

/*Dashboard Button Layout - 14-Sep-2022 */

.customer-plan .plan-head .button-part .cancel-btn,
.customer-plan .plan-head .button-part .hold-btn,
.customer-plan .plan-head .button-part .actionBtn {
  margin: 0 10px 0 0;
  box-shadow: none;
  background: transparent !important;
  border: 1px solid;
  border-radius: 4px;
  height: auto;
  line-height: normal;
  min-height: auto;
  text-transform: capitalize;
  font-size: 13px;
  font-weight: 300;
  position: relative;
  padding: 5px 15px;
  vertical-align: middle;
  float: left;
}
.customer-plan .plan-head .button-part .MuiButtonBase-root-27 .MuiButton-label-2 {
  line-height: normal;
}
.customer-plan .plan-head .button-part .cancel-btn, .customer-plan .plan-head .button-part .hold-btn {
  float: right;
}
.customer-plan .plan-head .button-part .actionBtn {
  position: relative;
}
.customer-plan .plan-head .button-part .actionBtn:after {
  display: inline-block;
  margin-left: .5em;
  vertical-align: .1em;
  content: "";
  border-top: .35em solid;
  border-right: .35em solid transparent;
  border-bottom: 0;
  border-left: .35em solid transparent;
}
.customer-plan .plan-head .button-part .actionBtn:hover::after {
  color: #fff;
}
.dropdownMenu ul {
  margin: 10px 0;
  padding: 0px !important;
  min-width: 150px;
}
.dropdownMenu ul li {
  padding: 5px 15px;
  font-size: 13px;
  color: #fff;
  line-height: normal;
  height: auto;
}


.customer-plan .plan-head .button-part .actionBtn.major,
.customer-plan .plan-head .button-part .actionBtn.major span {
  color: #354158;
  border-color: #354158;
}
.customer-plan .plan-head .button-part .actionBtn.major:hover{
  background-color: #354158 !important;
  border-color: #354158;
}
.customer-plan .plan-head .button-part .actionBtn.major:hover span {
  color:#fff;
}
#button-menu-major div[class*="MuiPaper-root-"] {
  background: #354158;
}


.customer-plan .plan-head .button-part .actionBtn.meals,
.customer-plan .plan-head .button-part .actionBtn.meals span {
  color: #6c9c70;
  border-color: #6c9c70;
}
.customer-plan .plan-head .button-part .actionBtn.meals:hover{
  background-color: #6c9c70 !important;
  border-color: #6c9c70;
}
.customer-plan .plan-head .button-part .actionBtn.meals:hover span {
  color:#fff;
}
#button-menu-meals div[class*="MuiPaper-root-"],.dd-list.dropdownMenu.meals {
  background: #6c9c70;
}


.customer-plan .plan-head .button-part .actionBtn.payment,
.customer-plan .plan-head .button-part .actionBtn.payment span {
  color: #9633c6;
  border-color: #9633c6;
}
.customer-plan .plan-head .button-part .actionBtn.payment:hover{
  background-color: #9633c6 !important;
  border-color: #9633c6;
}
.customer-plan .plan-head .button-part .actionBtn.payment:hover span {
  color:#fff;
}
#button-menu-payment div[class*="MuiPaper-root-"],.dd-list.dropdownMenu.payment {
  background: #9633c6;
}


.customer-plan .plan-head .button-part .actionBtn.logistics,
.customer-plan .plan-head .button-part .actionBtn.logistics span {
  color: #cd5b78;
  border-color: #cd5b78;
}
.customer-plan .plan-head .button-part .actionBtn.logistics:hover{
  background-color: #cd5b78 !important;
  border-color: #cd5b78;
}
.customer-plan .plan-head .button-part .actionBtn.logistics:hover span{
  color:#fff;
}
#button-menu-logistics div[class*="MuiPaper-root-"],.dd-list.dropdownMenu.logistics {
  background: #cd5b78;
}


.customer-plan .plan-head .button-part .actionBtn.downloads,
.customer-plan .plan-head .button-part .actionBtn.downloads span {
  color: #354159;
  border-color: #354159;
}
.customer-plan .plan-head .button-part .actionBtn.downloads:hover{
  background-color: #354159 !important;
  border-color: #354159;
}
.customer-plan .plan-head .button-part .actionBtn.downloads:hover span{
  color:#fff;
}
#button-menu-downloads div[class*="MuiPaper-root-"],.dd-list.dropdownMenu.downloads {
  background: #354159;
}


.customer-plan .plan-head .button-part .actionBtn.communications,
.customer-plan .plan-head .button-part .actionBtn.communications span {
  color: #8895d6;
  border-color: #8895d6;
}
.customer-plan .plan-head .button-part .actionBtn.communications:hover{
  background-color: #8895d6 !important;
  border-color: #8895d6;
}
.customer-plan .plan-head .button-part .actionBtn.communications:hover span{
  color:#fff;
}
#button-menu-communications div[class*="MuiPaper-root-"],.dd-list.dropdownMenu.communications {
  background: #8895d6;
}


.customer-plan .plan-head .button-part .actionBtn.packing,
.customer-plan .plan-head .button-part .actionBtn.packing span {
  color: #6c9c70;
  border-color: #6c9c70;
}
.customer-plan .plan-head .button-part .actionBtn.packing:hover{
  background-color: #6c9c70 !important;
  border-color: #6c9c70;
}
.customer-plan .plan-head .button-part .actionBtn.packing:hover span{
  color:#fff;
}
#button-menu-packing div[class*="MuiPaper-root-"],.dd-list.dropdownMenu.packing {
  background: #6c9c70;
}

.customer-plan .plan-head .button-part div.cancel-btn {
  color: #ED3C59;
  border-color: #ED3C59;
}
.customer-plan .plan-head .button-part div.cancel-btn:hover {
  color: #fff;
  background-color: #ED3C59 !important;
}

.customer-plan .plan-head .button-part div.hold-btn{
  color:#FB8423;
  border-color: #FB8423;
}
.customer-plan .plan-head .button-part div.hold-btn:hover{
  color:#fff;
  background-color: #FB8423 !important;
}
.plan-body .plan-head .future-buttons-part {
  padding: 20px;
  width: 98%;
  border-bottom: 1px solid #ababab;
}
.customer-plan .plan-head .button-part .dd-wrapper,.dd-wrapper{
  position: relative;
  margin: 0;
  padding: 0;
  background: transparent;
  box-shadow: none;
  float: left;
}
.customer-plan .plan-head .button-part .actionBtn.major span.dd-header-title {
  color: #354158;
}
.customer-plan .plan-head .button-part .actionBtn.major:hover span.dd-header-title{
  color: #fff;
}
.dd-list{
  outline: none;
  position: absolute;
  min-width: 16px;
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 16px;
  top: 28px;
  left: 0;
  background: #354158;
  text-align: left;
  padding: 10px;
  border-radius: 0px 0px 5px 5px;
}
.dd-list li{
transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
width: auto;
overflow: hidden;
white-space: nowrap;
font-size: 13px;
}
.customer-plan .plan-head .button-part .dd-header.actionBtn.dropOpen {
  border-radius: 5px 5px 0 0px;
}
.dd-list.dropdownMenu {
  min-width: 150px;
}
.stickyheader{
  position: relative;
  z-index: 1;
}
.roundedHeader.stickyheader tr th {
  position: sticky;
  top: 51px;
}
.primaryTableHeader.roundedHeader.stickyheader tr th{
  top: 100px;
  background-color: #354156;
  color: #fff;
  text-align: center;
}
.roundedHeader.stickyheader.financetbl tr th{
  top: 110px;
}
.button-link{
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.28), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  background-color: #001F3D;
  color: #FFFFFF;
  border: none;
  margin: 10px 1px;
  cursor: pointer;
  padding: 12px 30px;
  position: relative;
  font-size: 12px;
  transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  text-align: center;
  font-weight: 400;
  will-change: box-shadow;
  line-height: 1.42857143;
  white-space: nowrap;
  touch-action: manipulation;
  border-radius: 3px;
  text-transform: uppercase;
  letter-spacing: 0;
  vertical-align: middle;
  display: inline-block;
}
.button-link:focus {
  color: #fff;
}
.button-link:hover {
  color: #fff;
  box-shadow: 0 14px 26px -12px rgba(0, 0, 0, 0.28), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0,0, 0.2);
  background-color: #001F3D;
}
.diet-change{
  background: #ececec;
  padding: 5px;
  text-align: center;
}
.diet-name-edit {
  font-weight: bold;
  color: #414e65;
}
.customer-plan .plan-body .day.paused .status span {
  width: auto !important;
  padding: 0px 10px;
}
.customer-plan .plan-body .day.paused .status {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.customer-plan .plan-body .day.paused .status span.pausedday {
  padding: 5px 15px !important;
  text-transform: capitalize;
  font-size: 14px !important;
}
.marketList td{
  text-align: center;
}
.group.stickyheader td {
  position: sticky;
  top: 165px;
  background: #eee;
  color: #53597f;
  text-align: left;
}
.bold strong {
  font-weight: bold;
}
table.marketList {
  overflow-y: auto;
  height: 110px;
  border-collapse: collapse;        
  width: 100%;
}
.text-capitalize {
  text-transform: capitalize;
}
.title-heading{
  background-color: #D2EED2;
  display: block !important;
  text-align: center;
}
.mini-heading{
  background-color: #D2EED2;
}
.new-format .m-heading {
  display: block;
  text-align: center;
}
.Tabled td strong {
  font-weight: bold;
}
.tabsCheckout {
  float: left;
  width: 100%;
}
.afterTabsCheckout {
  float: left;
  width: 100%;
}
.dprogram {
  max-width: 200px;
}
.gistory .location.status.remove {
  position: relative;
  background: transparent;
  box-shadow: none;
  display: block;
  padding: 0;
  right: 0;
  top: 0;
}
.regular.history-remove {
  background-color: #f9b5b56b;
}
.regular.history-pending {
  background-color: rgba(86, 193, 152, 0.23);
}
.chef-dish.receipe-container h5, .chef-dish.receipe-container h6 {
  font-weight: bold;
  margin: 10px 0;
}
.chef-dish.receipe-container h5{
  margin: 0;
}
.chef-dish .rec-h-holder{
  margin-bottom: 15px;
}
.chef-dish.receipe-container{
  background-color: #fff;
  padding: 15px;
}
.col-lg-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%; 
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.PhoneInput{
  margin-top: 16px;
}
.PhoneInputInput {
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  padding-bottom: 5px;
  font: inherit;
}
.regular.mobile-checkout {
  padding: 10px;
}
.regular.mobile-checkout .price.bold,.regular.mobile-checkout .duration,.regular.mobile-checkout .location.status.remove  {
  font-size: 12px !important;
}
.regular.mobile-checkout .customer-desc p .name {
  font-size: 14px !important;
}
.regular.mobile-checkout .customer-desc p .types {
  font-size: 13px;
}