@media screen and (max-width: 1250px) {
  .customers-page .customer-desc .age-gender,
  .customers-page .customer-desc .phone{
    width: 100%;
    text-align: left;
  }
}
@media screen and (min-width: 770px) and (max-width: 1160px){
  .link-body .link-icon{
    display: none;
  }
  .link-body .link-text{
    text-align: center;
    width: 100%;
  }
}
@media screen and (max-width: 769px){
  .links-list{
    overflow: hidden;
  }
  .list-container{
    width: 100%;
    height: 100%;
    overflow-y: auto;
    box-sizing: content-box;
  }
  .link-body .link-text{
    display: none;
  }
  .link-body .link-icon{
    text-align: center;
    width: 100%;
  }
  .main-menu .link-body .link-icon{
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
