.modal-back-layer{
  position: fixed !important;
  z-index: 1250;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgba(0,0,0,0.4);
}
.modal-wrapper{
  background: #fff;
  position: relative;
  top: 15%;
  padding: 30px 15px 15px 15px;
  white-space: pre-line;
  margin: 0 auto;
}
.modal-wrapper .close-button{
  position: absolute;
  top: 0;
  right: 0;
  display: inline-block;
  height: 24px;
  width: 24px;
  text-align: center;
  line-height: 22px;
  cursor: pointer;
}
