.touch-spin{
  display: flex;
  justify-content: space-between;
  min-width: 100px;
  width: 100%;
  height: 2em;
  user-select: none;
}
.touch-spin .value-handler{

}
.touch-spin .butt{
  width: 2em;
  height: 100%;
  border: 1px solid #354156;
  box-sizing: border-box;
  text-align: center;
  vertical-align: text-top;
  font-size: 1.5em;
  cursor: pointer;
  background: #354156;
  color: #ffffff;
}
.butt.up:not(.disabled):hover{
  box-shadow: 1px 1px 2px 1px rgba(94, 118, 175, 0.62);
}
.butt.down:not(.disabled):hover{
  box-shadow: -1px 1px 2px 1px rgba(94, 118, 175, 0.62);
}
.butt.down{
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}
.butt.up{
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}
.touch-spin .value-handler{
  border: 1px solid #354156;
  border-left: none;
  border-right: none;
  box-sizing: border-box;
  width: calc(100% - 4em);
  text-align: center;
  background: #fefefe;
}
.value-handler>input{
  height: 100%;
  background: none;
  border: none;
  text-align: center;
  font-size: 1.5em;
  width: 100%;
}
.value-handler>span{
  height: 100%;
  vertical-align: text-top;
  font-size: 1.5em;
}
.butt>span{
  vertical-align: middle;
}
.butt.disabled{
  background: #7f8ca2;
  border-color: #7f8ca2;
}
.butt.up.disabled{
  border-left-color: #354156;
}
.butt.down.disabled{
  border-right-color: #354156;
}
