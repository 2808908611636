.rdt, .rdt table{
  width: 100%;
  user-select: none;
}
.rdtPicker table{
  box-shadow: 0 1px 5px #2a364b;
}
.rdtMonths table:first-child,
.rdtYears table:first-child{
  margin-bottom: 5px;
}
.rdt table tbody tr td,
.rdtDays table thead tr .dow{
  padding: 3% 2%;
  text-align: center;
  font-size: 13px;
}
.rdtDays tbody td{
  width: calc(100% / 7);
}
.rdtMonths tbody td,
.rdtYears tbody td{
  width: calc(100% / 4);
}
.rdt table tbody tr td{
  background: #414e65;
  border: 1px solid #f7f7f7;
  color: #d9e5fd;
}
.rdtNext, .rdtPrev, .rdtSwitch{
  background: #fff;
  color: #354156;
  font-size: 18px;
  letter-spacing: .05em;
  font-weight: 600;
  text-transform: uppercase;
  padding: 2%;
  cursor: pointer;
}
.rdt table tbody tr td.rdtOld,
.rdt table tbody tr td.rdtNew{
  color: #414e65;
  background: #b6bfd1;
}
.rdtPicker table thead tr th{
  background: #414e65;
  border: none;
  color: #e2cfa9;
}
.rdtPicker .rdtDays table thead tr .dow{
  background: #fff;
  color: #414e65;
  font-weight: bold;
}
.rdtDays table tbody tr .rdtDay:not(.disabled){
  cursor: pointer;
}
.rdtDays table tbody tr .rdtDay.rdtToday{
  color: #414e65;
  font-weight: 700;
  background: #c9a865;
}

.rdtDays table tbody tr .rdtDay.partial{
  background: #d2e6dd;
}
.rdtDays table tbody tr .rdtDay.full{
  background: #81b19b;
}
.rdtDays table tbody tr td.empty:not(.rdtToday):not(.rdtOld):not(.disabled):not(.selected):not(.rdtNew):not(.rdtActive){
  background: #6e8dc3;
}
table tbody tr td.selected, table tbody tr td.rdtActive{
  background: #fff;
  border: 1px solid #414e65;
  color: #414e65;
  font-weight: 700;
}
.rdtDay.disabled{
  filter: grayscale(100%);
  opacity: 0.7;
}
