.card-wrapper{
  display: flex;
  align-items: stretch;
  min-height: 150px;
}
.card-wrapper>div{
  display: inline-flex;
  box-sizing: border-box;
  flex-wrap: wrap;
}
.fields-part{
  justify-content: space-between;
  width: 80%;
}
.actions-part{
  align-content: space-evenly;
  width: 20%;
  padding: 0 10px;
}
.actions-part .button{
  width: 100%;
}
.fields-part div{
  box-sizing: border-box;
  padding: 5px 10px;
  width: calc(100% / 3);
}
.fields-part div span{
  font-size: small;
  color: #848484;
  width: 100%;
  display: inline-block;
}
.page-description, .page-url{
  width: 50%!important;
}
