.yes-no-wrapper{
  display: flex;
  flex-direction: column;
}
.yes-no-wrapper .button-text{
  margin-left: 10px;
}
.yes-no-wrapper .message{
  width: 100%;
  text-align: center;
  font-size: 1.5em;
  margin: 5px 0;
}
.yes-no-wrapper .buttons-handler{
  display: flex;
  margin-top: 10px;
  flex-direction: row;
  justify-content: space-between;
}

